<template>
  <div class="container podcastPlatforms">
    <div class="platforms-container">
      <podcast-platforms-item v-scroll-animation="'animate__fadeInDown'" :link="name" :slug="vale" v-for="(name,vale) in platforms"/>
    </div>
  </div>
</template>

<script>
import PodcastPlatformsItem from "@/components/smallComponents/podcastPlatforms";

export default {
  name: "podcastPlatforms",
  components: {PodcastPlatformsItem},
  props: {
    platforms: Object,
  },
};
</script>

<style lang="scss">
.podcastPlatforms {
  //margin-top: 3em;
  margin-top: 5em;
  margin-bottom: 3em;
  position: relative;
  z-index: 2;

  .platforms-container {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #fff;
    box-shadow: 0 0 15px rgba(#000, 0.15);
    border-radius: 3em;
    overflow: hidden;
    padding: 0.75em 1.5em;
    flex-wrap: wrap;

    .platform-item {
      overflow: hidden;
      padding: 1em 1em;
      flex-grow: 1;
      position: relative;

      &:last-of-type {
        border: none;
      }

      &:first-of-type {
        a {
          img {
            width: 4em;
          }
        }
      }

      a {
        display: flex;
        align-items: center;
        justify-content: center;

        img {
          width: 2.5em;
          object-fit: contain;
          //margin-inline-end: 0.75em;
          transition: 0.5s cubic-bezier(0.47, 1.64, 0.41, 0.8);
          transition-delay: 0.15s;

          &.alternative-image {
            position: absolute;
            top: 29%;
            right: 1.2%;
            transform: translateY(200%);
          }
        }

        p {
          margin-bottom: 0;
          color: rgba(#000, 0.85);
          font-weight: 700;
          font-size: 1.1rem;
          transition: 0.5s cubic-bezier(0.47, 1.64, 0.41, 0.8);
          transition-delay: 0.15s;
          margin-inline-end: 0.75em;

          &.alternative-text {
            position: absolute;
            top: 29%;
            right: 5.2em;
            transform: translateY(-200%);
          }
        }
      }

      //&:hover {
      //  img {
      //    transform: translateY(-200%);
      //    transition-delay: 0s;
      //
      //    &.alternative-image {
      //      transform: translateY(0%);
      //    }
      //  }
      //
      //  p {
      //    transform: translateY(200%);
      //    transition-delay: 0s;
      //
      //    &.alternative-text {
      //      transform: translateY(0%);
      //    }
      //  }
      //}
    }
  }
}

@media screen and (max-width: 992px) {
  .podcastPlatforms {
    .platforms-container {
      box-shadow: 0 0 12px rgba(#000000, 0.1);
      padding: 0.75em 0.5em;

      .platform-item {
        flex-grow: unset;

        a {
          img {
            width: 1.5em;
            &.alternative-image{
              display: none;
            }
          }

          p {
            font-size: 1rem;
            &.alternative-text {
              right: 48%;
              display: none;
            }
          }
        }
      }
    }
  }
}
</style>
