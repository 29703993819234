<template>
  <div class="my-audio-player">
    <audio
        @loadedmetadata="getAudioInfo"
        @pause="checkPlayerStatus"
        :id="this.audioPlayerID"
        :src="mp3Link"
        preload="metadata"
    ></audio>
    <!--  Audio Controls  -->
    <div class="audio-controls">
      <div class="control-sub-btn control-btn backward" @click="backwardAudio">
        <img src="@/assets/images/forwardBtn.png" alt="Forward Button">
        <p>10</p>
        <!--        <font-awesome-icon class="" icon="backward" />-->
      </div>
      <div class="control-main-btn control-btn" @click="changePlayerState">
        <font-awesome-icon icon="play" v-if="!this.playState"/>
        <font-awesome-icon icon="pause" v-else/>
      </div>
      <div class="control-sub-btn control-btn forward" @click="forwardAudio">
        <img src="@/assets/images/forwardBtn.png" alt="Forward Button">
        <p>10</p>
        <!--        <font-awesome-icon icon="forward" />-->
      </div>
    </div>
    <!--  Time Control  -->
    <div class="time-control">
      <p>{{ this.seekCurrent }}</p>
      <input
          type="range"
          min="0"
          :max="seekMax"
          :value="audioCurrentProgress"
          @input="updateCurrentTime"
      />
      <p>{{ this.duration }}</p>
    </div>
    <!--  Volume Control  -->
    <div class="volume-value">
      <font-awesome-icon icon="volume-up" v-if="volumeValue > 50"/>
      <font-awesome-icon
          icon="volume-down"
          v-else-if="volumeValue < 50 && volumeValue > 0"
      />
      <font-awesome-icon icon="volume-mute" v-else/>
      <input type="range" min="0" max="100" value="100" @input="changeVolume"/>
    </div>
  </div>
</template>

<script>
export default {
  name: "audioPlayer",
  props: {
    mp3Link: String,
    id: Number,
  },
  data() {
    return {
      duration: "00:00",
      volumeValue: 100,
      seekMax: 100,
      audioCurrentProgress: 0,
      seekCurrent: "00:00",
      playState: false,
      raf: null,
      audioPlayerID: "",
    };
  },
  created() {
    // console.log(this.mp3Link);
    this.audioPlayerID = 'audio-element' + this.id;
  },
  methods: {
    checkPlayerStatus() {
      if (this.playState == true) {
        this.changePlayerState();
      }
    },
    changePlayerState() {

      //Change play state
      this.playState = !this.playState;
      //Play and pause audio
      let audioElement = document.getElementById(this.audioPlayerID);
      if (this.playState) {
        audioElement.play();
        requestAnimationFrame(this.whilePlaying);
        //pause all other audio
        // debugger;
        let otherAudio = document.getElementsByTagName('audio');
        for (let i = 0; i < otherAudio.length; i++) {
          const item = otherAudio[i];
          if (item !== audioElement) {
            item.pause();
          }
        }
      } else {
        audioElement.pause();
        cancelAnimationFrame(this.raf);
      }
    },
    forwardAudio() {
      let forwardBtn = event.target;
      forwardBtn.classList.add("active");
      setTimeout(() => {
        forwardBtn.classList.remove("active")
      }, 500);
      let audioElement = document.getElementById(this.audioPlayerID);
      audioElement.currentTime += 10;
    },
    backwardAudio() {
      let backward = event.target;
      backward.classList.add("active");
      setTimeout(() => {
        backward.classList.remove("active")
      }, 500);
      let audioElement = document.getElementById(this.audioPlayerID);
      audioElement.currentTime -= 10;
    },
    whilePlaying() {
      let audioElement = document.getElementById(this.audioPlayerID);
      this.audioCurrentProgress = audioElement.currentTime;
      this.seekCurrent = this.calculateTime(audioElement.currentTime);
      this.raf = requestAnimationFrame(this.whilePlaying);
    },
    calculateTime(sec) {
      const minutes = Math.floor(sec / 60);
      const seconds = Math.floor(sec % 60);
      const returnedSeconds = seconds < 10 ? `0${seconds}` : `${seconds}`;
      return minutes + ":" + returnedSeconds;
    },
    getAudioInfo(e) {
      let durationInSecondes = e.target.duration;
      this.duration = this.calculateTime(durationInSecondes);
      //  Set Seek Max
      this.setSeekMax(Math.floor(durationInSecondes));
    },
    setSeekMax(seconds) {
      this.seekMax = seconds;
    },
    updateCurrentTime(e) {
      this.audioCurrentProgress = e.target.value;
      this.seekCurrent = this.calculateTime(e.target.value);
      let audioElement = document.getElementById(this.audioPlayerID);
      audioElement.currentTime = e.target.value;
    },
    changeVolume(e) {
      //Change volume background size
      var min = e.target.min,
          max = e.target.max,
          val = e.target.value;
      e.target.style.backgroundSize =
          ((val - min) * 100) / (max - min) + "% 100%";
      //Change volumeValue
      this.volumeValue = e.target.value;
      let audioElement = document.getElementById(this.audioPlayerID);
      audioElement.volume = this.volumeValue / 100;
    },
  },
};
</script>

<style scoped lang="scss">
.my-audio-player {
  direction: ltr;
  display: flex;
  align-items: center;
  margin-top: 1.5em;
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none;
  /* Non-prefixed version, currently
                                   supported by Chrome, Edge, Opera and Firefox */
  .time-control {
    flex-grow: 1;
    display: flex;
    align-items: center;
    padding: 0 1.5em;

    input {
      margin: 0 1em;
      flex-grow: 1;
      outline: none;
      width: 5em;
      height: 5px;
      margin-inline-start: 0.5em;
      background-color: rgba(#000000, 0.2);
      background-size: 100% 100%;
      background-repeat: no-repeat;
      border-radius: 10px;
      cursor: pointer;
      -webkit-appearance: none;

      &::-webkit-slider-runnable-track {
        box-shadow: none;
        border: none;
        background: transparent;
        -webkit-appearance: none;
      }

      &::-moz-range-track {
        box-shadow: none;
        border: none;
        background: transparent;
      }

      &::-moz-focus-outer {
        border: 0;
      }

      &::-webkit-slider-thumb {
        width: 14px;
        height: 14px;
        border: 0;
        background: $myBlue;
        border-radius: 100%;
        box-shadow: 0 0 1px 0px rgba(0, 0, 0, 0.1);
        -webkit-appearance: none;
      }

      &::-moz-range-thumb {
        width: 14px;
        height: 14px;
        border: 0;
        background: $myBlue;
        border-radius: 100%;
        box-shadow: 0 0 1px 0px rgba(0, 0, 0, 0.1);
      }
    }

    p {
      margin-bottom: 0;
    }
  }

  .volume-value {
    display: flex;
    align-items: center;

    svg {
      color: $myBlue;
    }

    input {
      margin: auto;
      outline: none;
      width: 5em;
      height: 5px;
      margin-inline-start: 0.5em;
      background-color: rgba(#000000, 0.2);
      background-image: -webkit-gradient(
              linear,
              50% 0%,
              50% 100%,
              color-stop(0%, $myBlue),
              color-stop(100%, $myBlue)
      );
      background-image: -webkit-linear-gradient($myBlue, $myBlue);
      background-image: -moz-linear-gradient($myBlue, $myBlue);
      background-image: -o-linear-gradient($myBlue, $myBlue);
      background-image: linear-gradient($myBlue, $myBlue);
      background-size: 100% 100%;
      background-repeat: no-repeat;
      border-radius: 10px;
      cursor: pointer;
      -webkit-appearance: none;

      &::-webkit-slider-runnable-track {
        box-shadow: none;
        border: none;
        background: transparent;
        -webkit-appearance: none;
      }

      &::-moz-range-track {
        box-shadow: none;
        border: none;
        background: transparent;
      }

      &::-moz-focus-outer {
        border: 0;
      }

      &::-webkit-slider-thumb {
        width: 14px;
        height: 14px;
        border: 0;
        background: $myYellow;
        border-radius: 100%;
        box-shadow: 0 0 1px 0px rgba(0, 0, 0, 0.1);
        -webkit-appearance: none;
      }

      &::-moz-range-thumb {
        width: 14px;
        height: 14px;
        border: 0;
        background: $myYellow;
        border-radius: 100%;
        box-shadow: 0 0 1px 0px rgba(0, 0, 0, 0.1);
      }
    }
  }

  .audio-controls {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-inline-end: 0.5em;

    .control-btn {
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      transition: 0.3s ease-in-out;

      svg {
        transition: 0.3s ease-in-out;
      }
    }

    .control-main-btn {
      width: 2.7em;
      height: 2.7em;
      margin: 0 0.75em;
      border: 2px solid $myBlue;

      svg {
        color: $myBlue;
      }

      &:hover {
        background-color: $myBlue;

        svg {
          color: #fff;
        }
      }
    }

    .control-sub-btn {
      width: 2.2em;
      height: 2.2em;
      position: relative;
      border: none;
      transition: 0.35s ease-in-out;

      &.backward {
        img {
          transform: scaleX(-1);
        }

        &.active {
          transform: rotate(-60deg);

          p {
            transform: translate(-50%, -50%) rotate(60deg);
          }
        }
      }

      &.active {
        transform: rotate(60deg);

        p {
          transform: translate(-50%, -50%) rotate(-60deg);
        }
      }

      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
        pointer-events: none;
      }

      p {
        position: absolute;
        pointer-events: none;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        transition: 0.35s ease-in-out;
      }

      svg {
        color: $myBlue;
      }
    }
  }
}

@media screen and (max-width: 567px) {
  .my-audio-player {
    flex-wrap: wrap;
    justify-content: space-evenly;

    .time-control {
      padding: 0 0.5em;
    }

    .volume-value {
      margin-top: 0.5em;
      width: 100%;
      justify-content: center;
      input{
        margin-inline-end: 0;
      }
    }

    .audio-controls {
      width: 100%;
      justify-content: center;
      .control-main-btn {
        width: 3.5em;
        height: 3.5em;
        margin: 0 0.35em;
        svg{
          font-size: 1.35rem;
        }
      }

      .control-sub-btn {
        width: 2.5em;
        height: 2.5em;

        svg {
          font-size: 0.85rem;
        }
      }
    }
  }
}
</style>
