import axios from "@/libs/axios";

export default {
  namespaced: true,
  state: {
    articles: "",
    singleArticle: "",
    authorPosts: "",
  },
  getters: {},
  mutations: {
    updateArticles(state, val) {
      state.articles = val;
    },
    updateSingleArticle(state, val) {
      state.singleArticle = val;
    },
    updateAuthorPosts(state, val) {
      state.authorPosts = val;
    },
  },
  actions: {
    getArticles({ commit }) {
      return new Promise((resolve, reject) => {
        axios
          .post("/blog")
          .then(function (response) {
            commit("updateArticles", response.data.posts);
            resolve(response);
          })
          .catch(function (error) {
            reject(error);
          });
      });
    },
    getSingleArticle({ commit }, payload) {
      return new Promise((resolve, reject) => {
        axios
          .post("/blog/single", payload)
          .then(function (response) {
            commit("updateSingleArticle", response.data.post);
            commit("updateAuthorPosts", response.data.author_posts);
            resolve(response);
          })
          .catch(function (error) {
            reject(error);
          });
      });
    },
  },
};
