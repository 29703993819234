<template>
  <div class="signUp-container" @click="closePopup">
    <div class="popupItem">
      <div class="top-part">
        <h1>أنشئ حساب</h1>
        <p>لديك حساب بالفعل <span @click="openSignInPopup">سجل دخول</span></p>
      </div>
      <div class="inputs-container">
        <div class="input-item">
          <font-awesome-icon :icon="['fas','user']"/>
          <input v-model="userName" type="text" placeholder="اسم المستخدم" required>
        </div>
        <div class="input-item">
          <font-awesome-icon :icon="['fas','envelope']"/>
          <input v-model="email" type="text" placeholder="البريد الإلكتروني" required>
        </div>
        <div class="input-item">
          <font-awesome-icon :icon="['fas','lock']"/>
          <input v-model="password" type="text" placeholder="كلمة المرور" required>
        </div>
      </div>
      <div class="bottom-part">
        <div class="row justify-content-end">
          <button @click="signUp" class="sign-btn">أنشئ حساب</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "signupPopup",
  data() {
    return {
      email: '',
      password: '',
      userName: '',
    }
  },
  methods: {
    closePopup(e) {
      if (e.target === document.getElementsByClassName("signUp-container")[0]) {
        this.$store.commit("generalStore/changeSignUpPopup");
      }
    },
    openSignInPopup() {
      this.$store.commit("generalStore/changeSignUpPopup");
      this.$store.commit("generalStore/changeSignInPopup");
    },
    signUp() {
      if (this.email !== '' && this.password !== '' && this.userName !== '') {
        this.$store.dispatch("loading/show");
        this.$store.dispatch("user/signUp", {
          email: this.email,
          name:this.userName,
          password:this.password,
          'confirm-password':this.password,
        })
            .then(res => {
              this.$store.dispatch("loading/hide");
            })
            .catch(err => {
              this.$store.dispatch("loading/hide");
              this.$swal({
                title: 'خطأ',
                icon: 'success',
                confirmButtonText: 'حسناً',
                text: 'حدث خطأ غير متوقع. رجاءً حاول مجدداً'
              });
              console.log(err.message)
            })
      } else {
        this.$swal({
          title: 'خطأ',
          icon: 'error',
          confirmButtonText: 'حسناً',
          text: 'تأكد رجاءً من جميع الحقول قبل الإرسال'
        });
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.signUp-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(#000000, 0.35);
  z-index: 15;
  display: flex;
  justify-content: center;
  align-items: center;

  .popupItem {
    padding: 2.5em 1em;
    background-color: rgba(#f5f6f7, 0.85);
    border-radius: 8px;
    backdrop-filter: blur(10px);
    width: 30em;
    max-width: 95%;

    .top-part {
      margin-bottom: 2em;

      h1 {
        font-weight: 700;
        margin-bottom: 0.25em;
      }

      p {
        font-weight: 500;

        span {
          margin-inline-start: 0.35em;
          color: #0d81c3;
          cursor: pointer;
        }
      }
    }

    .inputs-container {
      margin-bottom: 2em;

      .input-item {
        margin-bottom: 1.5em;
        display: flex;
        align-items: center;
        border-bottom: 1px solid rgba(#000000, 0.4);
        padding-bottom: 0.5em;

        svg {
          font-size: 1.8rem;
          margin-inline-end: 0.5em;
          margin-inline-start: 0.5em;
          color: $myBlue;
        }

        input {
          width: 100%;
          background-color: transparent;
          border: none;
          outline: none !important;

        }

        ::placeholder { /* Chrome/Opera/Safari */
          font-weight: 700;
          font-size: 1.2rem;
        }
      }
    }

    .bottom-part {
      .justify-content-between {
        margin-bottom: 2em;
        //flex-wrap: nowrap;
        a {
          width: auto;
          color: $myBlue;
          font-weight: 700;

          p {
            margin-bottom: 0;
            font-size: 1.2rem;
          }
        }

        .rememberMe-container {
          display: flex;
          align-items: center;
          width: auto;

          label {
            margin-inline-start: 0.5em;
            font-weight: 700;
            color: $myBlue;
            font-size: 1.2rem;
          }

          input {
            background-color: #ffffff;
            width: 1.35em;
            height: 1.35em;
            border-radius: 8px;
            border: none !important;
            outline: none !important;

            &:checked {
              background-color: $myViolet;
            }
          }
        }
      }

      .sign-btn {
        margin-inline-end: 0.5em;
        border: none;
        width: auto;
        font-size: 1.2rem;
        display: inline-block;
        padding: 0.25em 1.5em;
        border-radius: 1.5em;
        background-color: $myYellow;
        color: #ffffff;
        font-weight: 700;
      }
    }
  }
}
</style>