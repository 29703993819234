import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import axios from "axios";

// Bootstrap
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap";

//Swiper Code
import VueAwesomeSwiper from "vue-awesome-swiper";
import "swiper/swiper-bundle.min.css";
Vue.use(VueAwesomeSwiper);
import {
  Swiper as SwiperClass,
  Pagination,
  Mousewheel,
  Autoplay,
} from "swiper";
import getAwesomeSwiper from "vue-awesome-swiper/dist/exporter";
SwiperClass.use([Pagination, Mousewheel, Autoplay]);
Vue.use(getAwesomeSwiper(SwiperClass));
//End of Swiper code

//FontAwesome code
import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";
import { fab } from "@fortawesome/free-brands-svg-icons";
import { far } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
library.add(fas);
library.add(fab);
library.add(far);
Vue.component("font-awesome-icon", FontAwesomeIcon);
//End of FontAwesome code

//Use ScrollAnimation
import ScrollAnimation from "./directive/myScrollAnimation";
Vue.directive("scrollAnimation", ScrollAnimation);
//End of ScrollAnimation

Vue.config.productionTip = false;

// For Loading screen
axios.defaults.showLoader = true;

//Axios
import Axios from "@/libs/axios";
Vue.prototype.$http = Axios;

//Sweet Alert2
import VueSweetalert2 from "vue-sweetalert2";
import "sweetalert2/dist/sweetalert2.min.css";
Vue.use(VueSweetalert2);

//Vue meta
import VueMeta from "vue-meta";
Vue.use(VueMeta);

new Vue({
  created() {},
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
