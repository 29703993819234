<template>
  <div class="articlesSection">
    <div class="container">
      <div class="section-title">
        <div class="d-flex justify-content-between align-items-center">
          <h4 v-scroll-animation="'animate__slideInUp'">مقالات</h4>
          <router-link v-scroll-animation="'animate__slideInDown'" :to="{name:'Articles'}" class="link-btn"> المزيد
          </router-link>
        </div>
      </div>
      <div class="articlesContainer row">
        <!--    Article Item    -->
        <div class="col-lg-4 col-md-6 col-12" v-scroll-animation="'animate__zoomIn'" v-bind:key="item.id"
             v-for="item in articles">
          <router-link class="article-item" :to="{name:'singleArticle',params:{id:item.id}}">
            <img
                :src="item.image_full_path"
                alt="Article Image"
            />
            <div class="date">
              <p>{{ item.created_at }}</p>
            </div>
            <div class="title">
              <p>
                {{ item.title }}
              </p>
            </div>
            <div class="description">
              <p>
                {{ item.short_description }}
              </p>
            </div>
            <div class="author">
              <p>{{ item.blog_author.name }}</p>
            </div>
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "articlesSection",
  props: {
    articles: Array,
  },
};
</script>

<style lang="scss" scoped>
.articlesSection {
  padding-top: 4em;

  .section-title {
    padding-inline: 1em;
    padding-bottom: 1em;
    margin-bottom: 2em;
    border-bottom: 1px solid rgba(#000000, 0.25);

    h4 {
      font-weight: 700;
      font-size: 2.5rem;
    }

    .link-btn {
      background-color: $myViolet;
      margin-bottom: 0;
      height: fit-content;
      padding: 0.35em 2em;
      border-radius: 1.5em;
      color: white;
      font-weight: 700;
      transition: 0.3s ease-in-out;

      &:hover {
        background-color: $myYellow;
      }
    }
  }

  .articlesContainer {
    padding-top: 1em;
    display: flex;
    align-items: start;
    justify-content: center;
    flex-wrap: wrap;
    padding-bottom: 3em;

    .article-item {
      display: block;
      margin-bottom: 3em;

      img {
        border-radius: 8px;
        width: 100%;
        max-width: 100%;
        height: 15em;
        object-fit: cover;
        object-position: center;
        margin-bottom: 0.35em;
      }

      .date {
        p {
          font-size: 0.85rem;
          text-align: end;
          font-weight: 700;
          color: $myBlue;
          margin-bottom: 0;
        }
      }

      .title {
        p {
          font-weight: 700;
          color: $myViolet;
          font-size: 1.4rem;
        }
      }

      .description {
        p {
          color: $myBlue;
          font-size: 1.05rem;
          display: -webkit-box;
          -webkit-line-clamp: 3;
          -webkit-box-orient: vertical;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }

      .author {
        p {
          color: $myBlue;
          font-weight: 700;
          font-size: 1.1rem;
        }
      }
    }
  }
}

@media screen and (max-width: 991px) {
  .articlesSection{
    .articlesContainer{
      .col-lg-4{
        display: none;
        &:nth-child(1),&:nth-child(2),&:nth-child(3){
          display: block;
        }
      }
    }
  }
}
</style>
