<template>
  <div class="container newsletter-section">
    <div class="row">
      <div class="col-lg-4 mb-lg-0 mb-4">
        <div class="instagram-post" v-html="instagramPost">

        </div>
        <!--        <iframe class="instagram-post" :src="instagramPost"/>-->
      </div>
      <div class="col-lg-8 d-flex align-items-center justify-content-center">
        <div>
          <div class="d-flex text-part" v-scroll-animation="'animate__slideInLeft'">
            <font-awesome-icon icon="envelope-open-text"/>
            <p>اشترك في نشراتنا البريدية</p>
          </div>
          <div class="form-part" v-scroll-animation="'animate__slideInLeft'">
            <form id="newsletterForm" method="post" :action="this.$store.state.generalStore.mailchimpActionLink"
                  target="_blank">
              <input
                  type="email"
                  name="EMAIL"
                  placeholder="أدخل بريدك الإلكتروني"
                  required
              />
              <div class="submitNewsletterForm-button" @click="submitForm">
                <p>أرسل</p>
                <font-awesome-icon icon="paper-plane"/>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
export default {
  name: "newsLetter",
  props: {
    instagramPost: String,
  },
  mounted() {
    setInterval(() => {
      window.instgrm.Embeds.process();
    }, 5000);

  },
  methods: {
    submitForm() {
      debugger;
      let form = document.getElementById('newsletterForm');
      form.submit();
    },
  },
};
</script>

<style lang="scss">
.newsletter-section {
  padding-bottom: 4em;
  overflow-x: hidden;

  .col-lg-4 {
    .instagram-post {
      width: 100%;
      //aspect-ratio: 1/1;
      border-radius: 8px;
      overflow: hidden;

      .es-widget {
        width: 100% !important;

        .eapps-instagram-feed-posts-slider-inner {
          width: 100% !important;
        }
      }

      .eapps-instagram-feed-container {
        overflow: hidden;
      }
    }

    img {
      width: 100%;
      object-fit: cover;
      border-radius: 8px;
    }
  }

  .text-part {
    align-items: center;

    svg {
      font-size: 3.5rem;
      margin-inline-end: 0.35em;
      color: $myBlue;
    }

    p {
      font-weight: 700;
      margin-bottom: 0;
      font-size: 2.2rem;
    }
  }

  .form-part {
    margin-top: 1.5em;

    form {
      width: 100%;
      position: relative;

      input {
        border-radius: 1.4em;
        padding: 0.4em 1em;
        width: 100%;
        border: 2px solid rgba(#000, 0.2);
      }

      .submitNewsletterForm-button {
        position: absolute;
        top: 0;
        left: 0;
        background-color: $myBlue;
        width: 6em;
        border-radius: 6em;
        height: 2.52em;
        overflow: hidden;
        cursor: pointer;

        p,
        svg {
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
          margin-bottom: 0;
          color: white;
        }

        svg {
          left: 150%;
          transition: 0.3s cubic-bezier(0.47, 1.64, 0.41, 0.8);
          transition-delay: 0s;
          transform: translateY(-50%) scaleX(-0.8);
        }

        p {
          left: 50%;
          transform: translate(-50%, -50%);
          font-weight: 700;
          transition: 0.3s ease-in-out;
        }

        &:hover {
          svg {
            left: 50%;
            transform: translate(-50%, -50%) scaleX(-1);
            transition-delay: 0.3s;
          }

          p {
            left: -50%;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 992px) {
  .newsletter-section {
    .text-part {
      svg {
        font-size: 3rem;
      }

      p {
        font-size: 1.2rem;
      }
    }
  }
}
</style>
