<template>
  <header
      v-bind:class="{
      sticky: this.$store.state.generalStore.isNavbarSticky,
      homeNavbar: this.$route.path === '/'
    }"
  >
    <div class="container">
      <div class="row">
        <div class="col-md-5 col-4 actions-part">
          <!--          <div class="search-btn">-->
          <!--            <font-awesome-icon icon="search" @click="openSearchPopup"/>-->
          <!--          </div>-->
          <!--          <router-link :to="{name:'FavoritePage'}" class="user-action" v-if="$store.getters['user/getToken']">-->
          <!--            <img src="@/assets/images/user.svg.png" class="img-fluid" alt="User Image"/>-->
          <!--          </router-link>-->
          <!--          <div class="signIn-btn" @click="openSignInPopup" v-else>-->
          <!--            <p>تسجيل الدخول</p>-->
          <!--          </div>-->
        </div>
        <div class="col-md-2 col-4 logo-part">
          <router-link :to="{name:'englishPodcast'}">
            <img
                class="img-fluid"
                :src="this.$store.state.english.generalSettings.logo_white_full_path"
                alt="Tanwen Logo"
            />
            <img
                :src="this.$store.state.english.generalSettings.logo_full_path"
                alt="Tanwen Logo"
            />
          </router-link>
        </div>
        <div
            class="col-md-5 col-4 links-part"
            v-bind:class="{
            startAnimation:
              this.$store.state.generalStore.isLoadingAnimationEnded
          }"
        >
          <div class="links-container">
            <router-link :to="{name:'englishPodcast'}" @click.native="animateLinkLine"
            >Podcast
            </router-link
            >
            <router-link :to="{name:'EnAbout'}" @click.native="animateLinkLine"
            >About us
            </router-link
            >
            <router-link :to="{name:'EnContact'}" @click.native="animateLinkLine"
            >Contact us
            </router-link
            >
            <div class="line-under-link"></div>
          </div>
          <div class="drawer-btn just-mobile" @click="openDrawer">
            <div class="line"></div>
            <div class="line"></div>
            <div class="line"></div>
          </div>
        </div>
      </div>
    </div>
  </header>
</template>

<script>
export default {
  name: "EnNavbar",
  watch: {
    $route(to, from) {
      setTimeout(() => {
        let activeLink = document.querySelectorAll(
            ".links-container .router-link-exact-active"
        )[0];
        // debugger;
        if (activeLink === undefined) {
          let line = document.getElementsByClassName("line-under-link")[0];
          line.style.display = "none";
        } else {
          // console.log(activeLink);
          let width = activeLink.offsetWidth;
          let positionX = activeLink.offsetLeft;
          let line = document.getElementsByClassName("line-under-link")[0];
          line.style.display = "block";
          line.style.right = "auto";
          line.style.left = positionX + "px";
          line.style.width = width + "px";
        }

      }, 800);

    }
  },
  created() {
    //Get the width and position of active link
    setTimeout(() => {
      let activeLink = document.querySelectorAll(
          ".links-container .router-link-exact-active"
      )[0];
      if (activeLink != undefined) {
        let width = activeLink.offsetWidth;
        let positionX = activeLink.offsetLeft;
        let line = document.getElementsByClassName("line-under-link")[0];
        line.style.right = "auto";
        line.style.left = positionX + "px";
        line.style.width = width + "px";
      }
    }, 800);
  },
  methods: {
    animateLinkLine(element) {
      let width = element.target.offsetWidth;
      let positionX = element.target.offsetLeft;
      let line = document.getElementsByClassName("line-under-link")[0];
      line.style.right = "auto";
      line.style.left = positionX + "px";
      line.style.width = width + "px";
    },
    openSearchPopup() {
      this.$store.dispatch("generalStore/changeSearchPopupState");
    },
    handleScroll() {
      const currentScrollPosition =
          window.pageYOffset || document.documentElement.scrollTop;
      const navbarHeight =
          document.getElementsByTagName("Header")[0].offsetHeight;
      //update Sticky navbar
      this.$store.commit(
          "generalStore/changeNavbarSticky",
          currentScrollPosition > navbarHeight * 2
      );
      // console.log(currentScrollPosition + "," + navbarHeight);
    },
    openDrawer() {
      this.$store.commit("generalStore/changeDrawerState");
    },
    openSignInPopup() {
      this.$store.commit("generalStore/changeSignInPopup");
    },
    checkLoginState() {
      let tt = this.$store.getters["user/getToken"];
      return tt;
    }
  },
  mounted() {
    window.addEventListener("scroll", this.handleScroll);
  },
  beforeDestroy() {
    window.removeEventListener("scroll", this.handleScroll);
  },
}
</script>

<style lang="scss" scoped>
header {
  background-color: #fbfcfc;
  padding: 1.1em 0;
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  z-index: 100;
  box-shadow: 0 0 12px rgba(#000000, 0.15);
  //position: relative;

  .testLogo {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 4.5em;
  }

  &.homeNavbar {
    background-color: #2d4449;

    .logo-part {
      a {
        img {
          &:nth-child(1) {
            display: block;
          }

          &:nth-child(2) {
            display: none;
          }
        }
      }
    }

    .links-part {
      .links-container {
        a {
          color: #ffffff;

          &.router-link-exact-active {
            color: $myYellow;
          }
        }

        .line-under-link {
          background-color: $myYellow;
        }
      }

      .drawer-btn {
        .line {
          background-color: #ffffff;
        }
      }
    }

    .actions-part {
      .search-btn {
        svg {
          color: #ffffff;
        }
      }

      .user-action {
        img {
          background-color: rgba(#ffffff, 0.8);
        }
      }

      .signIn-btn {
        border-color: #ffffff;
        display: none;

        p {
          color: #ffffff;
        }

        &:hover {
          border-color: $myViolet;
        }
      }
    }
  }

  &.homeNavbar.sticky {
    //background-color: rgba(#2d4449, 0.7);
    //box-shadow: 0 0 12px rgba(#000000, 0.5);
    .logo-part {
      a {
        img {
          &:nth-child(1) {
            display: none;
          }

          &:nth-child(2) {
            display: block;
          }
        }
      }
    }

    .links-part {
      .links-container {
        a {
          color: $myBlue;

          &.router-link-exact-active {
            color: $myBlue;
          }
        }

        .line-under-link {
          background-color: $myBlue;
        }
      }

      .drawer-btn {
        .line {
          background-color: $myViolet;
        }
      }
    }

    .actions-part {
      svg {
        color: $myBlue;
      }

      .user-action {

        img {
          background-color: rgba($myBlue, 0.4);
        }
      }

      .signIn-btn {
        border-color: $myViolet;
        display: inline-block;

        p {
          color: $myViolet;
        }

        &:hover {
          p {
            color: #ffffff;
          }
        }
      }
    }
  }

  &.sticky {
    position: fixed;
    top: 0;
    animation: 0.5s backInDown $bounceAnimation;
    backdrop-filter: blur(10px);
    background-color: rgba(#efeff0, 0.8);
    box-shadow: 0 0 12px rgba(#000000, 0.25);
    //border-radius: 0 0 12px 12px;
    @keyframes backInDown {
      0% {
        transform: translateY(-100%);
      }
      100% {
        transform: translateY(0%);
      }
    }
  }

  .links-part {
    display: flex;
    justify-content: flex-end;

    &.startAnimation {
      .links-container {
        a {
          transform: translateY(0);
        }

        .line-under-link {
          bottom: 0;
          top: auto;
        }
      }

      .drawer-btn {
        transform: translateY(0);
      }

      .actions-part {
        svg {
          transform: translateY(0);
        }
      }
    }

    .links-container {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      height: 100%;
      position: relative;
      width: fit-content;

      a {
        color: #323232;
        margin-inline-end: 1em;
        transition: all 0.3s ease-in-out;
        transform: translateY(-250%);

        &:nth-child(1) {
          transition-delay: 0s;
        }

        &:nth-child(2) {
          transition-delay: 0.15s;
        }

        &:nth-child(3) {
          transition-delay: 0.3s;
        }

        &:nth-child(4) {
          transition-delay: 0.45s;
        }

        &:nth-child(5) {
          transition-delay: 0.6s;
        }

        &.router-link-exact-active {
          color: $myBlue;
          font-weight: 700;
        }
      }

      .line-under-link {
        position: absolute;
        bottom: auto;
        top: -50%;
        right: 0;
        width: 4em;
        height: 2px;
        background-color: $myBlue;
        transition: 0.4s ease-in-out;
        pointer-events: none;
      }
    }

    .drawer-btn {
      padding-inline-start: 0.5em;
      width: 2.5em;
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      justify-content: center;
      cursor: pointer;
      transform: translateY(-250%);
      transition: 0.4s ease-in-out;
      transition-delay: 0.75s;

      &:hover {
        .line {
          width: 100% !important;
        }
      }

      .line {
        height: 2px;
        width: 100%;
        background-color: $myViolet;
        margin-bottom: 0.35em;
        border-radius: 2px;
        transition: 0.35s $bounceAnimation;

        &:last-of-type {
          margin-bottom: 0;
        }

        &:nth-child(1) {
        }

        &:nth-child(2) {
          width: 50%;
        }

        &:nth-child(3) {
          width: 80%;
        }
      }
    }
  }

  .logo-part {
    display: flex;
    justify-content: center;
    align-items: center;

    a {
      img {
        width: 4.5em;
        -webkit-touch-callout: none; /* iOS Safari */
        -webkit-user-select: none; /* Safari */
        -khtml-user-select: none; /* Konqueror HTML */
        -moz-user-select: none; /* Old versions of Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
        user-select: none;
        /* Non-prefixed version, currently
                                         supported by Chrome, Edge, Opera and Firefox */
        &:nth-child(1) {
          display: none;
        }

        &:nth-child(2) {
          display: block;
        }
      }
    }
  }

  .actions-part {
    display: flex;
    justify-content: flex-start;
    align-items: center;

    .search-btn {
      margin-inline-end: 1em;

      svg {
        color: $myBlue;
        font-size: 1.3rem;
        cursor: pointer;
        transition: 0.4s $bounceAnimation;
      }
    }

    .signIn-btn {
      padding: 0.1em 0.6em;
      border-radius: 1.5em;
      border: 2px solid $myViolet;
      transition: 0.3s ease-in-out;
      cursor: pointer;
      user-select: none;

      &:hover {
        background-color: $myViolet;

        p {
          color: #ffffff;
        }
      }

      p {
        margin-bottom: 0;
        color: $myViolet;
        transition: 0.3s ease-in-out;
      }
    }

    .user-action {
      margin-inline-end: 1em;
      cursor: pointer;

      img {
        padding: 0.25em;
        width: 2em;
        height: 2em;
        border-radius: 50%;
        object-fit: cover;
        object-position: center;
        background-color: rgba($myBlue, 0.35);
      }
    }
  }
}

@media screen and (min-width: 991px) {
  .just-desktop {
    display: inline-block;
  }
  .just-mobile {
    display: none !important;
  }
}

@media screen and (max-width: 991px) {
  .just-desktop {
    display: none !important;
  }
  .just-mobile {
    display: inline-block;
  }
  header {
    .actions-part {
      .signIn-btn {
        display: none;
      }
    }

    .links-part {
      .links-container {
        display: none;

        .drawer-btn {
          transition-delay: 0s;
        }
      }
    }
  }
}
</style>