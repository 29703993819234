<template>
  <div
      id="app"
      v-bind:class="{
      disableScroll: this.$store.state.loading.loading,
      ltr: this.$store.state.english.isEnglish,
      rtl: !this.$store.state.english.isEnglish,
    }"
      v-bind:style="this.$store.state.generalStore.isNavbarSticky?'padding-top:78px;':''"
  >
    <!--  Loading Screen  -->
    <loading-screen/>
    <!--  Search Popup  -->
    <tanwen-search-popup
        v-if="this.$store.state.generalStore.searchPopupState"
    />
    <!--  Login Popup   -->
    <signin-popup v-if="this.$store.state.generalStore.signInPopup && !$store.state.english.isEnglish"/>
    <!--  Signup Popup  -->
    <signup-popup v-if="this.$store.state.generalStore.signUpPopup && !$store.state.english.isEnglish"/>
    <!--  Forget Password Popup  -->
    <forget-password-popup
        v-if="this.$store.state.generalStore.forgetPasswordPopup && !$store.state.english.isEnglish"/>
    <!--  Drawer  -->
    <tanwen-drawer
        v-bind:class="{ show: this.$store.state.generalStore.drawerOpen }" v-if="!$store.state.english.isEnglish"
    />
    <EnDrawer v-bind:class="{ show: this.$store.state.generalStore.drawerOpen }" v-else/>
    <!--  Navbar  -->
    <tanwen-navbar v-if="!$store.state.english.isEnglish"/>
    <EnNavbar v-else/>
    <router-view/>
    <!--  Footer  -->
    <tanwen-footer v-if="!$store.state.english.isEnglish"/>
    <EnFooter v-else/>
  </div>
</template>
<script>
import loadingScreen from "./components/LoadingScreen.vue";
import TanwenFooter from "./components/layoutComponents/tanwenFooter";
import TanwenNavbar from "@/components/layoutComponents/tanwenNavbar";
import TanwenSearchPopup from "@/components/layoutComponents/tanwenSearchPopup";
import TanwenDrawer from "@/components/layoutComponents/tanwenDrawer";
import SigninPopup from "@/components/layoutComponents/signinPopup";
import SignupPopup from "@/components/layoutComponents/signupPopup";
import ForgetPasswordPopup from "@/components/layoutComponents/forgetPasswordPopup";
import EnNavbar from "@/components/layoutComponents/english/EnNavbar";
import EnFooter from "@/components/layoutComponents/english/EnFooter";
import EnDrawer from "@/components/layoutComponents/english/EnDrawer";

export default {
  components: {
    EnDrawer,
    EnFooter,
    EnNavbar,
    ForgetPasswordPopup,
    SignupPopup,
    SigninPopup,
    TanwenDrawer,
    TanwenSearchPopup,
    TanwenNavbar,
    TanwenFooter,
    loadingScreen,
  },
  computed: {},
  created() {
    // localStorage.removeItem('accessToken');
  },
  metaInfo() {
    //  Change Fav-icon based on browser theme
    let isDark = window.matchMedia("(prefers-color-scheme: dark)").matches;
    return {
      link: [
        {
          rel: 'icon',
          href: isDark ? require('../src/assets/images/fav-white.png') : require('../src/assets/images/fav-colored.png')
        }
      ],
    }
  },
};
</script>

<style lang="scss">

#app {
  padding-top: 5em;
}

.ltr {
  @import url("https://cdn.jsdelivr.net/npm/bootstrap@5.0.2/dist/css/bootstrap.min.css");
  text-align: start !important;
  direction: ltr;
}

.rtl {
  @import url("https://cdn.jsdelivr.net/npm/bootstrap@5.0.2/dist/css/bootstrap.rtl.min.css");
  direction: rtl;

  input {
    direction: rtl;

    &[type='range'] {
      direction: ltr;
    }
  }
}

.swiper-container {
  .swiper-pagination {
    display: flex;
    justify-content: center;
    align-items: center;

    .swiper-pagination-bullet {
      background: rgba(#000000, 0.5);
      box-shadow: 0 0 12px rgba(#000000, 0.1);
      transition: 0.3s $bounceAnimation;

      &.swiper-pagination-bullet-active {
        background-color: $myYellow;
        width: 0.75em;
        height: 0.75em;
      }
    }
  }
}

@media screen and (max-width: 576px) {

  #app {
    --bs-gutter-x: 1.25rem;
  }

}


@font-face {
  font-family: "dubai";
  src: url("assets/font/DubaiW23-Bold.ttf") format("truetype"),
  url("assets/font/DubaiW23-Bold.eot"),
  url("assets/font/DubaiW23-Bold.woff") format("woff"),
  url("assets/font/DubaiW23-Bold.woff2") format("woff2");
  font-weight: 700;
}

@font-face {
  font-family: "dubai";
  src: url("assets/font/DubaiW23-Light.ttf") format("truetype"),
  url("assets/font/DubaiW23-Light.eot"),
  url("assets/font/DubaiW23-Light.woff") format("woff"),
  url("assets/font/DubaiW23-Light.woff2") format("woff2");
  font-weight: 200;
}

@font-face {
  font-family: "dubai";
  src: url("assets/font/DubaiW23-Regular.ttf") format("truetype"),
  url("assets/font/DubaiW23-Regular.eot"),
  url("assets/font/DubaiW23-Regular.woff") format("woff"),
  url("assets/font/DubaiW23-Regular.woff2") format("woff2");
  font-weight: 400;
}

@font-face {
  font-family: "dubai";
  src: url("assets/font/DubaiW23-Medium.ttf") format("truetype"),
  url("assets/font/DubaiW23-Medium.eot"),
  url("assets/font/DubaiW23-Medium.woff") format("woff"),
  url("assets/font/DubaiW23-Medium.woff2") format("woff2");
  font-weight: 500;
}

body {
  width: 100%;
  overflow-x: hidden;
  font-family: dubai, sans-serif !important;
}
</style>
