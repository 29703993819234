import axios from "@/libs/axios";

export default {
  namespaced: true,
  state: {
    searchResult: {
      done: false,
      podcast: [],
      videos: [],
      articles: [],
      podcastItemsCount: 0,
      articleItemsCount: 0,
      videosItemsCount: 0,
    },
  },
  getters: {},
  mutations: {
    updatePodcast(state, val) {
      state.searchResult.podcast = val;
      state.searchResult.podcastItemsCount = val.length;
    },
    updateVideos(state, val) {
      state.searchResult.videos = val;
      state.searchResult.videosItemsCount = val.length;
    },
    updateArticles(state, val) {
      state.searchResult.articles = val;
      state.searchResult.articleItemsCount = val.length;
    },
    isSearchDone(state, val) {
      state.searchResult.done = val;
    },
  },
  actions: {
    getAboutUsPageInfo({ commit }, payload) {
      return new Promise((resolve, reject) => {
        axios
          .post("/search", payload)
          .then(function (response) {
            if (response.data.media)
              commit("updateVideos", response.data.media);
            if (response.data.podcast_parts)
              commit("updatePodcast", response.data.podcast_parts);
            if (response.data.posts)
              commit("updateArticles", response.data.posts);
            commit("isSearchDone", true);
            resolve(response);
          })
          .catch(function (error) {
            reject(error);
          });
      });
    },
  },
};
