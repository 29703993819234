import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

// Modules
import loading from "./loading";
import generalStore from "@/store/generalStore";
import podcast from "@/store/podcast";
import aboutUs from "@/store/aboutUs";
import contactUs from "@/store/contactUs";
import services from "@/store/services";
import search from "@/store/search";
import videos from "@/store/videos";
import home from "@/store/homePage";
import articles from "@/store/articles";
import Courses from "@/store/courses";
import favorite from "@/store/favorite";
import user from "@/store/user";
import english from "@/store/english";

export default new Vuex.Store({
  state: {},
  mutations: {},
  actions: {},
  modules: {
    loading,
    generalStore,
    podcast,
    aboutUs,
    contactUs,
    services,
    search,
    videos,
    home,
    articles,
    Courses,
    favorite,
    user,
    english,
  },
  strict: process.env.DEV,
});
