import axios from "@/libs/axios";

export default {
  namespaced: true,
  state: {
    aboutUsInfo: "",
  },
  getters: {},
  mutations: {
    updateInfo(state, val) {
      state.aboutUsInfo = val;
    },
  },
  actions: {
    getAboutUsPageInfo({ commit }) {
      return new Promise((resolve, reject) => {
        axios
          .post("/about_us")
          .then(function (response) {
            commit("updateInfo", response.data);
            resolve(response);
          })
          .catch(function (error) {
            reject(error);
          });
      });
    },
  },
};
