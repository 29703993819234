<template>
  <div class="homeHero">
    <div class="section-content">
      <h1 v-scroll-animation="'animate__fadeInDown'">{{ title }}</h1>
      <p v-scroll-animation="'animate__fadeInDown'">
        {{ desc }}
      </p>
      <div class="actions">
        <button class="signIn-btn" v-scroll-animation="'animate__fadeInDown'" @click="openSignInPopup"
                v-show="!$store.getters['user/getToken']">تسجيل الدخول
        </button>
      </div>
    </div>
    <div class="floating-images">
      <img
          v-scroll-animation="'animate__zoomIn'"
          class="mic-image"
          src="@/assets/images/mic-image.png"
          alt="Mic Image"
      />
      <div class="circles-img">
        <div class="animated-image">
          <div class="circle circle-1"></div>
          <div class="circle circle-2"></div>
          <div class="circle circle-3"></div>
        </div>

      </div>
    </div>
    <div class="curves-bg">
      <img
          src="@/assets/images/bgWave.svg"
          alt="curves backgrround"
      />
    </div>
    <div class="curveWrapper">
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320">
        <path
            fill="#fdfdfd"
            fill-opacity="1"
            d="M0,256L80,261.3C160,267,320,277,480,245.3C640,213,800,139,960,106.7C1120,75,1280,85,1360,90.7L1440,96L1440,320L1360,320C1280,320,1120,320,960,320C800,320,640,320,480,320C320,320,160,320,80,320L0,320Z"
        ></path>
      </svg>
    </div>
  </div>
</template>

<script>
export default {
  name: "homeHeroSection",
  props: {
    title: String,
    desc: String,
  },
  methods: {
    openSignInPopup() {
      this.$store.commit("generalStore/changeSignInPopup");
    },
  },
};
</script>

<style lang="scss" scoped>
.homeHero {
  position: relative;
  background: linear-gradient(75deg, #3e5960, #1b3035);
  height: 115vh;
  overflow: hidden;
  margin-bottom: -2em;

  .curveWrapper {
    position: absolute;
    z-index: 3;
    bottom: 0;
    left: 0;
    width: 100%;
  }

  .section-content {
    position: absolute;
    top: 40%;
    right: 10%;
    transform: translateY(-50%);
    z-index: 4;
    padding-inline-start: 6em;
    width: 40%;

    h1 {
      color: #fff;
      font-weight: 700;
      font-size: 3.8rem;
      margin-bottom: 0.85em;
    }

    p {
      color: #fff;
      margin-bottom: 2em;
    }

    .signIn-btn {
      border: 2px solid $myYellow;
      border-radius: 1.5em;
      padding: 0.35em 1.5em;
      text-align: center;
      background: transparent;
      color: #ffffff;
      font-weight: 700;
      transition: 0.3s ease-in-out;

      &:hover {
        background: $myYellow;
        color: $myBlue;
      }
    }
  }

  .curves-bg {
    position: absolute;
    bottom: 0;
    left: 0;
    height: 60vh;
    width: 100%;
    z-index: 1;

    img {
      width: 100%;
      object-fit: cover;
    }
  }

  .floating-images {
    position: absolute;
    bottom: 0;
    left: 0;
    z-index: 2;

    .mic-image {
      position: absolute;
      left: 15vw;
      bottom: 6vh;
      height: 90vh;
    }

    .circles-img {
      position: absolute;
      left: 23vw;
      bottom: 75vh;
      @keyframes myScale {
        0% {
          transform: translate(-50%, -50%) scale(1);
          opacity: 1;
        }
        100% {
          transform: translate(-50%, -50%) scale(0);
          opacity: 0;
        }
      }

      .animated-image {
        position: relative;
        width: 14em;
        height: 14em;
        display: flex;
        justify-content: center;
        align-items: center;

        .circle {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          width: 14em;
          height: 14em;
          border: 3px solid rgba(#ffffff, 0.9);
          border-radius: 50%;
          animation: myScale ease-in-out 3s infinite;
        }

        .circle.circle-2 {
          animation-delay: 1s;
        }

        .circle.circle-3 {
          animation-delay: 2s;
        }
      }
    }


  }
}

@media screen and (max-width: 992px) {
  .homeHero {
    overflow: unset;
    height: 65vh;

    .curves-bg{
      height: auto;
    }

    .floating-images {
      .mic-image {
        display: none;
      }

      .circles-img {
        display: none;
      }
    }

    .section-content {
      width: 80vw;
      padding-inline-start: 0;
    }

    .curveWrapper {
      bottom: -0.2em;
    }
  }
}
</style>
