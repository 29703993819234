import axios from "@/libs/axios";

export default {
  namespaced: true,
  state: {
    podcastPage: {
      programs: [],
      featuredPrograms: [],
      podcastCategory: [],
      podcastRegion: [],
      listeningHours: 0,
      productionHours: 0,
      programsCount: 0,
    },
    programPage: null,
    singleEpisodePage: null,
    moreEpisodes: [],
    allPrograms: [],
  },
  getters: {},
  mutations: {
    updatePrograms(state, val) {
      state.podcastPage.programs = val;
    },
    updateFeaturedPrograms(state, val) {
      state.podcastPage.featuredPrograms = val;
    },
    updatePodcastCategory(state, val) {
      state.podcastPage.podcastCategory = val;
    },
    updatePodcastRegion(state, val) {
      state.podcastPage.podcastRegion = val;
    },
    updateListeningHours(state, val) {
      state.podcastPage.listeningHours = val;
    },
    updateProductionHours(state, val) {
      state.podcastPage.productionHours = val;
    },
    updateProgramsCount(state, val) {
      state.podcastPage.programsCount = val;
    },
    updateProgramPageInfo(state, val) {
      state.programPage = val;
    },
    updateSingleEpisodePageInfo(state, val) {
      state.singleEpisodePage = val;
    },
    updateMoreEpisode(state, val) {
      state.moreEpisodes = val;
    },
    updateEpisodeFavStatus(state) {
      state.singleEpisodePage.is_in_fav = !state.singleEpisodePage.is_in_fav;
    },
    updateAllPrograms(state, val) {
      state.allPrograms = val;
    },
  },
  actions: {
    getPodcastPageInfo({ commit }) {
      return new Promise((resolve, reject) => {
        axios
          .post("/podcast/page")
          .then(function (response) {
            commit("updateFeaturedPrograms", response.data.featured_programs);
            commit("updateListeningHours", response.data.listing_hours);
            commit("updatePrograms", response.data.programs);
            commit("updateProductionHours", response.data.production_hours);
            commit("updatePodcastCategory", response.data.podcast_category);
            commit("updatePodcastRegion", response.data.podcast_region);
            commit("updateProgramsCount", response.data.programs_count);
            resolve(response);
          })
          .catch(function (error) {
            reject(error);
          });
      });
    },
    getProgramInfo({ commit }, payload) {
      return new Promise((resolve, reject) => {
        axios
          .post("/podcast/program", payload)
          .then(function (response) {
            commit("updateProgramPageInfo", response.data);
            resolve(response);
          })
          .catch(function (error) {
            reject(error);
          });
      });
    },
    getSingleEpisodePageInfo({ commit }, payload) {
      return new Promise((resolve, reject) => {
        axios
          .post("/podcast/part", payload)
          .then(function (response) {
            commit("updateSingleEpisodePageInfo", response.data.part);
            commit("updateMoreEpisode", response.data.more_parts);
            resolve(response);
          })
          .catch(function (error) {
            reject(error);
          });
      });
    },
    getAllPrograms({ commit }) {
      return new Promise((resolve, reject) => {
        axios
          .post("/podcast/programs")
          .then(function (response) {
            commit("updateAllPrograms", response.data.programs);
            resolve(response);
          })
          .catch(function (error) {
            reject(error);
          });
      });
    },
  },
};
