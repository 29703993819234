<template>
  <footer v-if="this.$store.state.english.generalSettings">
    <div class="container">
      <div class="row px-lg-5 justify-content-center">
        <div class="col-lg-6 col-md-10 col-12">
          <div class="top-part" v-if="this.$store.state.english.generalSettings.social_media.length>0">
            <a :href="social.url" target="_blank" :title="social.icon" v-bind:key="social.id + social.icon"
               v-for="social in this.$store.state.english.generalSettings.social_media">
              <font-awesome-icon :icon="['fab', social.icon]"/>
            </a>
          </div>
          <hr/>
          <div class="bottom-part">
            <p>{{ this.$store.state.english.generalSettings.footer_copywriters }}</p>
          </div>
        </div>

      </div>
    </div>
  </footer>
</template>

<script>
export default {
  name: "EnFooter",
  created() {
    this.$store.dispatch("english/getGeneralSettings")
        .then(() => {
        }).catch(err => {
      this.$swal({
        title: 'Error',
        icon: 'error',
        confirmButtonText: 'Ok',
        text: 'Some kind of error happened please try reopen the site.'
      });
    });
  },
}
</script>

<style lang="scss" scoped>
footer {
  background-color: #ffffff;
  padding: 3em 0 2em 0;
  position: relative;
  z-index: 4;

  .top-part {
    display: flex;
    justify-content: center;
    align-items: center;
    column-gap: 1em;

    a {
      &:hover {
        svg {
          color: $myYellow;
        }
      }

      svg {
        font-size: 1.4rem;
        color: $myBlue;
        transition: 0.3s ease-in-out;
      }
    }
  }

  .bottom-part {
    p {
      text-align: center;
      font-weight: 500;
    }
  }
}

@media screen and (max-width: 992px) {
  footer {
    padding-bottom: 0;
  }
}
</style>