import axios from "@/libs/axios";

export default {
  namespaced: true,
  state: {
    isEnglish: false,
    podcastPage: "",
    programPage: "",
    episodePage: "",
    moreEpisodes: "",
    aboutPage: "",
    contactPage: "",
    generalSettings: "",
    searchResultsEn: "",
    searchStateDone: false,
    searchPodcastPartEn: "",
  },
  getters: {},
  mutations: {
    updateIsEnglish(state, val) {
      state.isEnglish = val;
    },
    updatePodcastPage(state, val) {
      state.podcastPage = val;
    },
    updateProgramPage(state, val) {
      state.programPage = val;
    },
    updateEpisodePage(state, val) {
      state.episodePage = val;
    },
    updateMoreEpsidos(state, val) {
      state.moreEpisodes = val;
    },
    updateAboutPage(state, val) {
      state.aboutPage = val;
    },
    updateContactPage(state, val) {
      state.contactPage = val;
    },
    updateGeneralSettings(state, val) {
      state.generalSettings = val;
    },
    updateSearchResultsEn(state, val) {
      state.searchResultsEn = val;
    },
    updatesearchPodcastPartEn(state, val) {
      state.searchPodcastPartEn = val;
    },
    updateSearchState(state, val) {
      state.searchStateDone = val;
    },
  },
  actions: {
    getPodcastPage({ commit }) {
      return new Promise((resolve, reject) => {
        axios
          .post("/en/podcast/page")
          .then(function (response) {
            commit("updatePodcastPage", response.data);
            resolve(response);
          })
          .catch(function (error) {
            console.log(error.statusCode);
            reject(error);
          });
      });
    },
    getProgramDetails({ commit }, payload) {
      return new Promise((resolve, reject) => {
        axios
          .post("/en/podcast/program", payload)
          .then(function (response) {
            commit("updateProgramPage", response.data.program);
            resolve(response);
          })
          .catch(function (error) {
            console.log(error.statusCode);
            reject(error);
          });
      });
    },
    getEpisodeDetails({ commit }, payload) {
      return new Promise((resolve, reject) => {
        axios
          .post("/en/podcast/part", payload)
          .then(function (response) {
            commit("updateEpisodePage", response.data.part);
            commit("updateMoreEpsidos", response.data.more_parts);
            resolve(response);
          })
          .catch(function (error) {
            console.log(error.statusCode);
            reject(error);
          });
      });
    },
    getAboutPage({ commit }) {
      return new Promise((resolve, reject) => {
        axios
          .post("/en/about_us")
          .then(function (response) {
            commit("updateAboutPage", response.data);
            resolve(response);
          })
          .catch(function (error) {
            console.log(error.statusCode);
            reject(error);
          });
      });
    },
    getContactPage({ commit }) {
      return new Promise((resolve, reject) => {
        axios
          .post("/en/contactus")
          .then(function (response) {
            commit("updateContactPage", response.data);
            resolve(response);
          })
          .catch(function (error) {
            console.log(error.statusCode);
            reject(error);
          });
      });
    },
    getGeneralSettings({ commit }) {
      return new Promise((resolve, reject) => {
        axios
          .post("/en/general")
          .then(function (response) {
            commit("updateGeneralSettings", response.data);
            resolve(response);
          })
          .catch(function (error) {
            console.log(error.statusCode);
            reject(error);
          });
      });
    },
    getSearchResult({ commit, state }, payload) {
      commit("updateSearchState", false);
      //commit("updateSearchResults", "");
      return new Promise((resolve, reject) => {
        axios
          .post("/en/search", payload)
          .then(function (response) {
            commit("updateSearchResultsEn", response.data);
            // commit("updatesearchPodcastPartEn", response.data.podcast_parts_en);
            commit("updateSearchState", true);
            // console.dir(response.data.podcast_parts_en);
            resolve(response);
          })
          .catch(function (error) {
            commit("updateSearchState", true);
            console.log(error.statusCode);
            reject(error);
          });
      });
    },
  },
};
