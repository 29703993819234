<template>
  <div class="videosSection">
    <div class="container">
      <div class="section-title">
        <div class="d-flex justify-content-between align-items-center">
          <h4 v-scroll-animation="'animate__fadeInDown'">مرئيات</h4>
          <router-link v-scroll-animation="'animate__fadeInDown'" :to="{name:'Videos'}" class="link-btn"> المزيد
          </router-link>
        </div>
      </div>
      <div class="section-videos row">
        <div class="col-lg-8" v-scroll-animation="'animate__zoomIn'" v-bind:class="{'col-lg-12':media.length===1}"
             v-html="media[mainVideoIndex].youtube_frame">
        </div>
        <div class="col-lg-4">
          <div class="video-item" v-scroll-animation="'animate__zoomIn'"
               v-bind:key="item.id" v-for="item in media.filter((value, index) => mainVideoIndex !== index)"
               @click="updateMainVideoIndex(media.indexOf(item))">
            <img class="img-fluid" :src="$store.state.generalStore.urlPrefixForImages+item.featured_image"
                 alt="Video Thumbnail" v-if="!isMobile">
            <div v-html="item.youtube_frame" v-else/>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "videosSection",
  props: {
    media: Array,
  },
  data() {
    return {
      mainVideoIndex: 0,
      isMobile:false,
    };
  },
  methods: {
    updateMainVideoIndex(index) {
      this.mainVideoIndex = index;
    },
    onResize() {
      this.isMobile = window.innerWidth < 991;
    },
  },
  mounted() {
    window.addEventListener('resize', this.onResize, { passive: true })
  },
  created() {
    this.onResize();
    // console.log(this.isMobile);
  },
  beforeDestroy () {
    if (typeof window !== 'undefined') {
      window.removeEventListener('resize', this.onResize, { passive: true })
    }
  }
};
</script>

<style lang="scss">
.videosSection {
  background-color: #f6f5fa;
  //margin-top: -6em;
  margin-top: 1em;
  padding-bottom: 3em;

  .section-title {
    padding-top: 9em;
    padding-inline: 1em;
    padding-bottom: 1em;
    margin-bottom: 2em;
    border-bottom: 1px solid rgba(#000000, 0.25);

    h4 {
      font-weight: 700;
      font-size: 2.5rem;
    }

    .link-btn {
      background-color: $myYellow;
      margin-bottom: 0;
      height: fit-content;
      padding: 0.35em 2em;
      border-radius: 1.5em;
      color: white;
      font-weight: 700;
      font-size: 1.15rem;
      transition: 0.3s ease-in-out;

      &:hover {
        background-color: $myViolet;
      }
    }
  }

  .section-videos {
    overflow: hidden;
    padding-top: 1em;

    iframe {
      border-radius: 8px;
      overflow: hidden;
    }

    .col-lg-8 {
      border-inline-end: 1px solid rgba(#000000, 0.25);

      iframe {
        width: 100% !important;
        height: 30em !important;
      }
    }

    .col-lg-4 {
      height: 30em;
      overflow-y: scroll;
      overflow-x: hidden;

      iframe, img {
        cursor: pointer;
        border-radius: 8px;
        width: 100% !important;
        height: calc(15em - 0.75em) !important;
        margin-bottom: 0.75em;
        object-fit: cover;
      }
    }
  }
}

@media screen and (max-width: 992px) {
  .videosSection {
    .section-title {
      padding-top: 7em;
    }

    .section-videos {
      .col-lg-8 {
        border: none;
        iframe {
          //height: calc(15em - 0.75em) !important;
          height: auto !important;
          aspect-ratio: 16/9;
          margin-bottom: 0.75em;
        }
      }
      .col-lg-4{
        height: unset;
        .video-item{
          display: none;
          iframe{
            height: auto !important;
            object-fit: unset;
            aspect-ratio: 16/9;
          }
          &:nth-child(1),&:nth-child(2){
            display: block;
          }
        }
      }
    }

  }
}
</style>
