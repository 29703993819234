<template>
  <div class="home" v-if="this.$store.state.home.homePageInfo">
    <home-hero-section :title="this.$store.state.home.homePageInfo.first_section.title"
                       :desc="this.$store.state.home.homePageInfo.first_section.text"/>
    <home-slider :programs="this.$store.state.home.homePageInfo.featured_programs"
                 :title="this.$store.state.home.homePageInfo.second_section.title"
                 :sub-title="this.$store.state.home.homePageInfo.second_section.text"/>
    <home-episodes :episodes="this.$store.state.home.homePageInfo.last_parts"/>
    <podcast-platforms :platforms="this.$store.state.home.homePageInfo.podcast_urls"/>
<!--    <enjoy-english-version/>-->
    <news-letter :instagram-post="this.$store.state.home.homePageInfo.instagram_post"/>

    <videos-section :media="this.$store.state.home.homePageInfo.last_multi_media"/>
    <articles-section :articles="this.$store.state.home.homePageInfo.last_posts"/>
    <tanwen-services :services="this.$store.state.home.homePageInfo.form_sections"
                     :main-service="this.$store.state.home.homePageInfo.services_section"/>
  </div>
</template>
<style lang="scss" scoped>
.home {
  background-color: #fdfdfd;
}
</style>

<script>
import HomeSlider from "../components/home components/homeSlider.vue";
import HomeEpisodes from "../components/home components/homeEpisodes";
import EnjoyEnglishVersion from "../components/home components/enjoyEnglishVersion";
import NewsLetter from "../components/home components/newsLetter";
import PodcastPlatforms from "../components/home components/podcastPlatforms";
import VideosSection from "../components/home components/videosSection";
import ArticlesSection from "../components/home components/articlesSection";
import TanwenServices from "../components/home components/tanwenServices";
import HomeHeroSection from "../components/home components/homeHeroSection";

export default {
  name: "Home",
  data() {
    return {};
  },
  components: {
    HomeHeroSection,
    TanwenServices,
    ArticlesSection,
    VideosSection,
    PodcastPlatforms,
    NewsLetter,
    EnjoyEnglishVersion,
    HomeEpisodes,
    HomeSlider,
  },
  created() {
    this.$store.dispatch("loading/show");
    this.$store.dispatch("home/getAboutUsPageInfo")
        .then(() => {
          this.$store.dispatch("loading/hide");
        })
        .catch(err => {
          console.log(err);
        })
    // this.$store.dispatch("loading/show");
    // setTimeout(this.hideLoadingScreen, 3000);
  },
  methods: {
    hideLoadingScreen() {
      this.$store.dispatch("loading/hide");
    },
  },
};
</script>
