<template>
  <div
      class="loading-container"
      v-bind:class="{
      show: this.$store.state.loading.loading || !animationEnded,
      Ending: isEnding,
      // show: true,
      // Ending: false,
    }"
  >
    <div class="loading-screen">
      <div class="image-container">
        <img
            class="gif-logo"
            @load="gifLoaded"
            id="animatedLogo"
            :src="logoSrc"
            alt="Animated Tanween logo"
        />
        <img
            class="white-logo"
            src="@/assets/images/tanwen-white-logo.png"
            alt="Tanwen Logo"
        />
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      isEnding: false,
      animationEnded: false,
      logoSrc: require("../assets/gifs/animatedLogoWithYellowDot.gif"),
      interval: null,
    };
  },
  watch: {
    $route(to, from) {
      this.logoSrc = this.logoSrc + "?" + Math.random();
    },
    // '$store.state.generalStore.isLoadingAnimationEnded'() {
    //   console.log(this.$store.state.generalStore.isLoadingAnimationEnded);
    // }
  },
  methods: {
    GifEnded() {
      if (!this.$store.state.loading.loading) {
        this.isEnding = true;
        clearInterval(this.interval);
        // console.log("Interval Ended!!");
        setTimeout(() => {
          this.animationEnded = true;
          setTimeout(() => {
            this.isEnding = false;
            this.$store.commit("generalStore/changeLoadingAnimationState");
          }, 1500);
        }, 1500);
      }
    },
    gifLoaded() {
      setTimeout(() => {
        this.GifEnded();
      }, 3350);
      // this.interval = setInterval(this.GifEnded, 6500);
    },
  },
  created() {
    //Rerun gif from start when open
    this.logoSrc = this.logoSrc + "?" + Math.random();
  },
};
</script>
<style lang="scss">
.loading-container {
  &.Ending {
    height: 5em;
    //width: 100vw;
    transition: 1.5s ease-in-out;
    .loading-screen{
      top: 0;
      .image-container{
        height: 100%;
        width: 100%;
        position: relative;
      }
    }

    .image-container {
      img {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 4.5em;
        max-width: 90vw;

        &.gif-logo {
          opacity: 0;
          display: none;
        }

        &.white-logo {
          opacity: 1;
        }
      }
    }
  }
}
//@media screen and (min-width: 1400px){
//  .loading-container{
//    &.Ending {
//      .image-container {
//        img {
//          &.white-logo {
//            left: 49.10%;
//          }
//        }
//      }
//    }
//  }
//}

.loading-container {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999999;
  opacity: 0;
  height: 100vh;
  //width: calc(100vw - (100vw - 100%));
  width: 100vw;
  background: linear-gradient(120deg, #435f65, #1b3034);

  pointer-events: none;

  img {
    width: 60em;
    max-width: 90vw;
    transition: 1.5s ease-in-out;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    &.white-logo {
      width: 18.8em;
      opacity: 0;
    }
  }
}

.loading-screen {
  top: 0;
  right: 0;
  /* display: flex;
  justify-content: center;
  align-items: center; */
  height: 100%;
  position: relative;
  width: 100%;
}

.loading-container.show {
  opacity: 1;
  pointer-events: all;
}

//.loading-container.show .loadingio-spinner-spinner-sq8md4t8fhm {
//  position: fixed;
//  top: 50%;
//  left: 50%;
//  transform: translate(-50%, -50%);
//}
//
//.loadingio-spinner-spinner-sq8md4t8fhm {
//  position: fixed;
//  top: -100%;
//  left: 50%;
//  transform: translateX(-50%);
//  transition: 0.3s ease-in-out;
//  display: inline-block;
//  overflow: hidden;
//}
</style>
