import axios from "@/libs/axios";

export default {
  namespaced: true,
  state: {
    servicesInfo: "",
    singleService: "",
  },
  getters: {},
  mutations: {
    updateInfo(state, val) {
      state.servicesInfo = val;
    },
    updateSingleService(state, val) {
      state.singleService = val;
    },
  },
  actions: {
    getAboutUsPageInfo({ commit }) {
      return new Promise((resolve, reject) => {
        axios
          .post("/services")
          .then(function (response) {
            commit("updateInfo", response.data);
            resolve(response);
          })
          .catch(function (error) {
            reject(error);
          });
      });
    },
    getSingleService({ commit }, payload) {
      return new Promise((resolve, reject) => {
        axios
          .post("/form/get", payload)
          .then(function (response) {
            commit("updateSingleService", response.data.form);
            resolve(response);
          })
          .catch(function (error) {
            reject(error);
          });
      });
    },
    submitForm({ commit }, payload) {
      return new Promise((resolve, reject) => {
        axios
          .post("/form/submit", payload)
          .then(function (response) {
            resolve(response);
          })
          .catch(function (error) {
            reject(error);
          });
      });
    },
  },
};
