import axios from "@/libs/axios";

export default {
  namespaced: true,
  state: {
    lastMedia: "",
    mediaCategoriesWithPosts: "",
    singleMediaPage: "",
  },
  getters: {},
  mutations: {
    updateLastMedia(state, val) {
      state.lastMedia = val;
    },
    updateMediaCategories(state, val) {
      state.mediaCategoriesWithPosts = val;
    },
    updateSingleMedaPage(state, val) {
      state.singleMediaPage = val;
    },
  },
  actions: {
    getAboutUsPageInfo({ commit }) {
      return new Promise((resolve, reject) => {
        axios
          .post("/multimedia")
          .then(function (response) {
            commit("updateLastMedia", response.data.last_media);
            commit(
              "updateMediaCategories",
              response.data.media_categories_with_posts
            );
            resolve(response);
          })
          .catch(function (error) {
            reject(error);
          });
      });
    },
    getSingleMediaInfo({ commit }, payload) {
      return new Promise((resolve, reject) => {
        axios
          .post("/multimedia/single", payload)
          .then(function (response) {
            commit("updateSingleMedaPage", response.data);
            resolve(response);
          })
          .catch(function (error) {
            reject(error);
          });
      });
    },
  },
};
