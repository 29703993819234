import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";
import store from "@/store";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
  },
  {
    path: "/search/:keyword/:isPodcast?",
    name: "Search",
    component: () => import(/* webpackChunkName: "search" */ "../views/search"),
  },
  {
    path: "/about",
    name: "About",
    component: () => import(/* webpackChunkName: "about" */ "../views/about"),
  },
  {
    path: "/contact",
    name: "ContactUS",
    component: () =>
      import(/* webpackChunkName: "contact" */ "../views/contact"),
  },
  {
    path: "/services",
    name: "Services",
    component: () =>
      import(/* webpackChunkName: "services" */ "../views/services"),
  },
  {
    path: "/service/:id",
    name: "SingleService",
    component: () =>
      import(/* webpackChunkName: "services" */ "../views/singleService"),
  },
  {
    path: "/podcast",
    name: "Podcast",
    component: () =>
      import(/* webpackChunkName: "podcast" */ "../views/podcast"),
  },
  {
    path: "/episode/:id",
    name: "Episode",
    component: () =>
      import(/* webpackChunkName: "podcast" */ "../views/episode"),
  },
  {
    path: "/program/:id",
    name: "Program",
    component: () =>
      import(/* webpackChunkName: "podcast" */ "../views/program"),
  },
  {
    path: "/videos",
    name: "Videos",
    component: () => import(/* webpackChunkName: "videos" */ "../views/videos"),
  },
  {
    path: "/videos/:id",
    name: "singleVideo",
    component: () =>
      import(/* webpackChunkName: "videos" */ "../views/singleMedia"),
  },
  {
    path: "/articles",
    name: "Articles",
    component: () =>
      import(/* webpackChunkName: "articles" */ "../views/articles"),
  },
  {
    path: "/articles/:id",
    name: "singleArticle",
    component: () =>
      import(/* webpackChunkName: "articles" */ "../views/singleArticle"),
  },
  {
    path: "/courses",
    name: "Courses",
    component: () =>
      import(/* webpackChunkName: "courses" */ "../views/courses"),
  },
  {
    path: "/courses/:id",
    name: "SingleCourse",
    component: () =>
      import(/* webpackChunkName: "courses" */ "../views/singleCourse"),
  },
  {
    path: "/playlist/:isFav/:id/:isRegion",
    name: "Favorite",
    component: () =>
      import(/* webpackChunkName: "playList" */ "../views/playList"),
  },
  {
    path: "/favorite",
    name: "FavoritePage",
    component: () =>
      import(/* webpackChunkName: "favorite" */ "../views/favorite"),
  },
  {
    path: "/programs",
    name: "Programs",
    component: () =>
      import(/* webpackChunkName: "programs" */ "../views/allPrograms"),
  },
  //  English Version
  {
    path: "/en",
    name: "englishPodcast",
    component: () =>
      import(/* webpackChunkName: "programs" */ "../views/english/podcast"),
  },
  {
    path: "/en/program/:id",
    name: "EnProgram",
    component: () =>
      import(/* webpackChunkName: "programs" */ "../views/english/program"),
  },
  {
    path: "/en/episode/:id",
    name: "EnEpisode",
    component: () =>
      import(/* webpackChunkName: "programs" */ "../views/english/episode"),
  },
  {
    path: "/en/about",
    name: "EnAbout",
    component: () =>
      import(/* webpackChunkName: "programs" */ "../views/english/about"),
  },
  {
    path: "/en/contact",
    name: "EnContact",
    component: () =>
      import(/* webpackChunkName: "programs" */ "../views/english/contact"),
  },
  {
    path: "/en/search/:keyword/:isPodcast?",
    name: "EnSearch",
    component: () =>
      import(/* webpackChunkName: "programs" */ "../views/english/search"),
  },
  //    404 Page
  {
    path: "/404",
    name: "notFoundPage",
    component: () =>
      import(/* webpackChunkName: "courses" */ "../views/notFondPage"),
  },
  {
    path: "*",
    redirect: "/404",
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return { savedPosition };
    } else {
      return { x: 0, y: 0 };
    }
  },
});

router.beforeEach((to, from, next) => {
  if (to.path === "/en" || to.path.includes("/en/")) {
    makeLtr();
  } else {
    makeRtl();
  }
  next();
});

function makeRtl() {
  let htmlEl = document.querySelector("html");
  htmlEl.setAttribute("dir", "rtl");
  htmlEl.setAttribute("lang", "ar");
  store.commit("english/updateIsEnglish", false);
}

function makeLtr() {
  let htmlEl = document.querySelector("html");
  htmlEl.setAttribute("dir", "ltr");
  htmlEl.setAttribute("lang", "en");
  store.commit("english/updateIsEnglish", true);
}

router.afterEach(() => {});

export default router;
