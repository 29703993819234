import Vue from "vue";

import axios from "axios";
import router from "@/router";

const axiosIns = axios.create({
  // baseURL: "https://jsonplaceholder.typicode.com",
  // baseURL: "http://192.168.1.2:8000/api",
  // baseURL: "http://tanween.zettaprog.com/api",
  baseURL: "https://admin.tanwenmedia.com/api",
});

axiosIns.defaults.headers.common["Authorization"] = "Bearer " + getToken();
axiosIns.defaults.headers.common["Accept"] = "application/json";

axiosIns.interceptors.response.use(
  function (response) {
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data
    return response;
  },
  function (error) {
    if (error.response.status === 404) {
      router.push("/404");
    }
    if (error.response.status === 405) {
      router.push("/");
    }

    return Promise.reject(error);
  }
);

function getToken() {
  let item = JSON.parse(localStorage.getItem("accessToken"));
  if (!item) {
    return null;
  }
  if (item.expiry < new Date().getTime()) {
    localStorage.removeItem("accessToken");
    return null;
  }
  // console.log(item.value);
  return item.value;
}

Vue.prototype.$http = axiosIns;

export default axiosIns;
