<template>
  <div class="container home-new-episodes">
    <h4 class="section-title" v-scroll-animation="'animate__fadeInDown'">حلقات جديدة</h4>
    <div class="episodes-container">
      <!--   Episode Card   -->
      <home-episode-card :image="item.image_full_path" :title="item.title" :mp3-link="item.main_mp3_url"
                         :duration="item.audio_time" :date="item.created_at" :id="item.id"
                         :program-name="item.podcast_program.title" v-bind:key="item.id"
                         :desc="item.short_description"
                         :category-name="item.podcast_category.name"
                         :program-id="item.podcast_program.id"
                         :category-id="item.podcast_category.id"
                         v-for="item in episodes" v-scroll-animation="'animate__fadeInDown'"/>

    </div>
    <div class="more-btn">
      <router-link :to="{ name: 'Favorite', params: {isFav:1,id:0,isRegion:0} }" v-scroll-animation="'animate__zoomIn'"> المزيد من الحلقات الجديدة</router-link>
    </div>
  </div>
</template>

<style lang="scss">
.home-new-episodes {
  padding: 6em 2em 2em 2em !important;
  background-color: rgba(#000000, 0.035);
  margin-bottom: 3em;
  margin-top: 2em;
  //margin-top: -3.8em;
  h4.section-title {
    padding-inline-start: 1.2em;
    font-size: 2.5rem;
    font-weight: 700;
    margin-bottom: 2em;
  }

  .episodes-container {
    margin-bottom: 3em;
  }

  .more-btn {
    display: flex;
    justify-content: center;
    padding-bottom: 1em;

    a {
      border-radius: 1.5em;
      border: 2px solid $myBlue;
      background-color: transparent;
      padding: 0.25em 1.5em;
      color: rgba($myBlue, 1);
      font-weight: 700;
      font-size: 1.4rem;
      transition: 0.3s ease-in-out;
      &:hover{
        background-color: $myBlue;
        color: rgba(#ffffff, 1);
      }
    }
  }
}

@media screen and (max-width: 992px) {
  .home-new-episodes {
    padding-left: 1em !important;
    padding-right: 1em !important;
    padding-top: 3em !important;

    h4 {
      &.section-title {
        font-size: 1.5rem;
        margin-bottom: 1.5em;
      }
    }
  }
}
</style>

<script>
import HomeEpisodeCard from "./homeEpisodeCard";

export default {
  components: {HomeEpisodeCard},
  props: {
    episodes: Array,
  },
  data() {
    return {};
  },
};
</script>
