import axios from "@/libs/axios";
import router from "@/router";

export default {
  namespaced: true,
  state: {
    user: "",
  },
  getters: {
    getToken() {
      let item = JSON.parse(localStorage.getItem("accessToken"));
      if (!item) {
        return false;
      }
      if (item.expiry < new Date().getTime()) {
        localStorage.removeItem("accessToken");
        return false;
      }
      return item.value;
    },
  },
  mutations: {
    updateUser(state, val) {
      state.user = val;
    },
  },
  actions: {
    signIn({ commit }, payload) {
      return new Promise((resolve, reject) => {
        axios
          .post("/login", payload)
          .then(function (response) {
            commit("updateUser", response.data.user);
            saveAccessToken(response.data.access_token);
            resolve(response);
          })
          .catch(function (error) {
            reject(error);
          });
      });
    },
    signUp({ commit }, payload) {
      return new Promise((resolve, reject) => {
        axios
          .post("/singup", payload)
          .then(function (response) {
            commit("updateUser", response.data.user);
            saveAccessToken(response.data.access_token);
            resolve(response);
          })
          .catch(function (error) {
            reject(error);
          });
      });
    },
    resetPassword({ commit }, payload) {
      return new Promise((resolve, reject) => {
        axios
          .post("/reset_password", payload)
          .then(function (response) {
            resolve(response);
          })
          .catch(function (error) {
            reject(error);
          });
      });
    },
  },
};

function saveAccessToken(accessToken) {
  const item = {
    value: accessToken,
    expiry: new Date().getTime() + 86400000,
  };
  localStorage.setItem("accessToken", JSON.stringify(item));
  axios.defaults.headers.common["Authorization"] = "Bearer " + accessToken;
  router.go(0);
}
