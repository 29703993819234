import axios from "@/libs/axios";

export default {
  namespaced: true,
  state: {
    searchPopupState: false,
    isNavbarSticky: false,
    drawerOpen: false,
    isLoadingAnimationEnded: false,
    socialMedia: "",
    footerCopyRight: "",
    urlPrefixForImages: "",
    tanwenWhiteLogo: "",
    tanwenColoredLogo: "",
    drawerTime: "",
    drawerTimeType: "",
    mailchimpActionLink: "",
    signInPopup: false,
    signUpPopup: false,
    forgetPasswordPopup: false,
  },
  getters: {},
  mutations: {
    changeSearchPopupState(state) {
      state.searchPopupState = !state.searchPopupState;
    },
    changeNavbarSticky(state, val) {
      state.isNavbarSticky = val;
    },
    changeDrawerState(state) {
      state.drawerOpen = !state.drawerOpen;
      let realCurrentTime = new Date().toLocaleTimeString([], {
        hour: "2-digit",
        minute: "2-digit",
      });
      state.drawerTimeType = realCurrentTime.substring(
        0,
        realCurrentTime.length - 2
      );
      state.drawerTime = new Date()
        .toLocaleTimeString([], {
          hour: "2-digit",
          minute: "2-digit",
        })
        .slice(-2);
    },
    changeLoadingAnimationState(state) {
      // state.isLoadingAnimationEnded = !state.isLoadingAnimationEnded;
      state.isLoadingAnimationEnded = true;
    },
    updateSocialMedia(state, val) {
      state.socialMedia = val;
    },
    updateCopyRight(state, val) {
      state.footerCopyRight = val;
    },
    updateImagesPrefix(state, val) {
      state.urlPrefixForImages = val;
    },
    updateWhiteLogo(state, val) {
      state.tanwenWhiteLogo = val;
    },
    updateColoredLogo(state, val) {
      state.tanwenColoredLogo = val;
    },
    updateMailchimpActionLink(state, val) {
      state.mailchimpActionLink = val;
    },
    changeSignInPopup(state) {
      state.signInPopup = !state.signInPopup;
    },
    changeSignUpPopup(state) {
      state.signUpPopup = !state.signUpPopup;
    },
    changeForgetPasswordPopup(state) {
      state.forgetPasswordPopup = !state.forgetPasswordPopup;
    },
  },
  actions: {
    changeSearchPopupState({ commit }) {
      commit("changeSearchPopupState");
    },
    getGeneralInfo({ commit }) {
      return new Promise((resolve, reject) => {
        axios
          .post("/general")
          .then(function (response) {
            commit("updateWhiteLogo", response.data.logo_white_full_path);
            commit("updateColoredLogo", response.data.logo_full_path);
            commit("updateCopyRight", response.data.footer_copywriters);
            commit("updateImagesPrefix", response.data.images_prefix);
            commit("updateSocialMedia", response.data.social_media);
            commit("updateMailchimpActionLink", response.data.mailchimp_action);
            resolve(response);
          })
          .catch(function (error) {
            reject(error);
          });
      });
    },
  },
};
