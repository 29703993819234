<template>
  <footer>
    <div class="container">
      <!--   Desktop version   -->
      <div class="row px-lg-5 just-desktop">
        <div class="col-lg-3 col-12">
          <router-link :to="{name:'Podcast'}" class="mainLink">بودكاست</router-link>
          <router-link :to="{name:'Programs'}" class="normalLink">برامج تنوين</router-link>
          <router-link :to="{ name: 'Favorite', params: {isFav:1,id:0,isRegion:0} }" class="normalLink">
            أحدث الحلقات
          </router-link>
        </div>
        <div class="col-lg-3 col-12">
          <router-link :to="{name:'Services'}" class="mainLink">خدماتنا</router-link>
          <router-link :to="{name:'SingleService',params:{id:6}}" class="normalLink">الإنتاج الصوتي</router-link>
          <router-link :to="{name:'SingleService',params:{id:9}}" class="normalLink"
          >الإنتاج المرئي
          </router-link>
          <!--          <router-link :to="{name:'Courses'}" class="normalLink">الدورات</router-link>-->
        </div>
        <div class="col-lg-3 col-12">
          <router-link :to="{name:'ContactUS'}" class="mainLink">تواصل معنا</router-link>
          <router-link :to="{name:'SingleService',params:{id:1}}" class="normalLink">أضف بودكاست</router-link>
          <router-link :to="{name:'SingleService',params:{id:3}}" class="normalLink">اكتب مع تنوين</router-link>
          <router-link :to="{name:'SingleService',params:{id:4}}" class="normalLink">انضم لتنوين</router-link>
        </div>
        <div class="col-lg-3 col-12">
          <div class="social-links" v-if="this.$store.state.generalStore.socialMedia">
            <a :href="social.url" target="_blank" :title="social.icon" v-bind:key="social.id"
               v-for="social in this.$store.state.generalStore.socialMedia">
              <font-awesome-icon
                  :icon="['fab', social.icon]"
              ></font-awesome-icon>
            </a>
          </div>
          <hr v-if="this.$store.state.generalStore.socialMedia"/>
          <div class="copyrights">
            <p>
              {{ this.$store.state.generalStore.footerCopyRight }}
            </p>
            <p class="poweredBy">طوِّرَ من قبل <a href="https://zettaprog.com/" target="_blank">ZettaProg</a></p>
          </div>
        </div>
      </div>
      <!--   Mobile Version   -->
      <div class="row mobile-footer">
        <div class="myCol">
          <router-link :to="{name:'Programs'}" class="normalLink">برامج تنوين</router-link>
        </div>
        <div class="myCol">
          <router-link :to="{name:'Videos'}" class="normalLink">مرئيات</router-link>
        </div>
        <div class="myCol">
          <router-link :to="{name:'Services'}" class="normalLink">خدمات تنوين</router-link>
        </div>
        <div class="myCol">
          <router-link :to="{ name: 'Favorite', params: {isFav:1,id:0,isRegion:0} }" class="normalLink">
            أحدث الحلقات
          </router-link>
        </div>
        <div class="myCol">
          <router-link :to="{name:'Articles'}" class="normalLink">
            مقالات
          </router-link>
        </div>
        <div class="myCol">
          <router-link :to="{name:'SingleService',params:{id:3}}" class="normalLink">اكتب مع تنوين</router-link>
        </div>
        <div class="col-12 mt-4">
          <div class="social-links" v-if="this.$store.state.generalStore.socialMedia">
            <a :href="social.url" target="_blank" :title="social.icon" v-bind:key="social.id"
               v-for="social in this.$store.state.generalStore.socialMedia">
              <font-awesome-icon
                  :icon="['fab', social.icon]"
              ></font-awesome-icon>
            </a>
          </div>
          <hr v-if="this.$store.state.generalStore.socialMedia"/>
          <div class="copyrights">
            <p>
              {{ this.$store.state.generalStore.footerCopyRight }}
            </p>
            <p class="poweredBy">طوِّرَ من قبل <a href="https://zettaprog.com/" target="_blank">ZettaProg</a></p>
          </div>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
export default {
  name: "tanwenFooter",
  created() {
    this.$store.dispatch("generalStore/getGeneralInfo")
        .then(() => {
        }).catch(err => {
      this.$swal({
        title: 'حدث خطأ ما',
        icon: 'error',
        confirmButtonText: 'حسناً',
        text: 'حدث خطأ غير متوقع أعد فتح الموقع من جديد'
      });
    });
  },
};
</script>

<style lang="scss" scoped>
footer {
  background-color: #ffffff;
  padding: 3em 0 2em 0;
  position: relative;
  z-index: 4;

  .just-desktop {
    display: flex;
  }
  .mobile-footer{
    display: none;
  }

  .mainLink {
    color: $myViolet;
    font-weight: 700;
    display: block;
    font-size: 1.5rem;
    margin-bottom: 0.75em;
  }

  .normalLink {
    display: block;
    color: $myBlue;
    font-weight: 500;
    font-size: 1.1rem;
    margin-bottom: 0.5em;
  }

  .social-links {
    display: flex;
    align-items: center;
    justify-content: space-evenly;

    a {
      display: inline-block;
      color: $myBlue;
      font-size: 1.5rem;
      transition: 0.3s ease-in-out;

      &:hover {
        color: $myYellow;
      }
    }
  }

  hr {
    margin-top: 0.4em;
  }

  .copyrights {
    p {
      text-align: center;
      font-weight: 500;
      font-size: 1.1rem;
    }

    .poweredBy {
      font-size: 0.9rem;
      visibility: hidden;

      a {
        color: $myBlue
      }
    }
  }
  .mobile-footer{
    .myCol{
      //flex: 1;
      width: 33%;
    }
    .normalLink{
      border-inline-start: 1px solid $myBlue;
      padding-inline-start: 0.5em;
      //font-size: 1rem;
      font-weight: 700;
    }
  }

}

@media screen and (max-width: 992px) {
  footer {
    padding-bottom: 0em;

    .just-desktop {
      display: none;
    }
    .mobile-footer{
      display: flex;
    }

    .col-lg-3 {
      margin-bottom: 1em;

      &:last-of-type {
        margin-bottom: 0;
      }

      .mainLink {
        text-align: center;
        margin-bottom: 0.25em;
      }

      .normalLink {
        text-align: center;
      }
    }

  }
}
@media screen and (max-width: 576px){
  footer{
    .mobile-footer{
      .normalLink{
        font-size: 1rem;
      }
    }
  }
}
@media screen and (max-width: 400px){
  footer{
    .mobile-footer{
      .normalLink{
        font-size: 0.85rem;
      }
    }
  }
}
</style>
