import axios from "@/libs/axios";

export default {
  namespaced: true,
  state: {
    favItems: "",
    playListItems: "",
    playlistName: "",
  },
  getters: {},
  mutations: {
    updateFavItems(state, val) {
      state.favItems = val;
    },
    updatePlaylist(state, val) {
      state.playListItems = val;
    },
    updatePlaylistName(state, val) {
      state.playlistName = val;
    },
  },
  actions: {
    getPlaylist({ commit }, payload) {
      return new Promise((resolve, reject) => {
        axios
          .post("/podcast/category", payload)
          .then(function (response) {
            commit("updatePlaylist", response.data.podcast_parts);
            commit("updatePlaylistName", response.data.name);
            resolve(response);
          })
          .catch(function (error) {
            debugger;
            console.log(error.statusCode);
            reject(error);
          });
      });
    },
    getFavItems({ commit }) {
      return new Promise((resolve, reject) => {
        axios
          .post("/favorite/my")
          .then(function (response) {
            commit("updateFavItems", response.data.fav_podcast_parts);
            resolve(response);
          })
          .catch(function (error) {
            reject(error);
          });
      });
    },
    addFavItem({ commit }, payload) {
      return new Promise((resolve, reject) => {
        axios
          .post("/favorite/add", payload)
          .then(function (response) {
            resolve(response);
          })
          .catch(function (error) {
            reject(error);
          });
      });
    },
    removeItemFromFav({ commit }, payload) {
      return new Promise((resolve, reject) => {
        axios
          .post("/favorite/remove", payload)
          .then(function (response) {
            resolve(response);
          })
          .catch(function (error) {
            reject(error);
          });
      });
    },
  },
};
