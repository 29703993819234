<template>
  <div class="episode-card">
    <div class="row">
      <div class="col-lg-3">
        <router-link :to="{name:'Episode',params:{id:id}}">
          <img
              class="img-fluid"
              :src="image"
              alt="Episode Image"
          />
<!--          <img-->
<!--              class="img-fluid"-->
<!--              src="https://via.placeholder.com/1000"-->
<!--              alt="Episode Image"-->
<!--          />-->
        </router-link>
      </div>
      <div class="col-lg-9">
        <div class="episode-remaining-info">
          <router-link :to="{name:'Episode',params:{id:id}}" class="episode-name">
            <h4 class="episode-name">{{ title }}</h4>
          </router-link>

          <div class="episode-info">
            <router-link :to="{name:'Program',params:{id:ProgramId}}">{{ programName }}</router-link>
            <router-link :to="{ name: 'Favorite', params: {isFav:0,id:categoryId,isRegion:0} }">{{ CategoryName }}</router-link>
            <p>{{ duration }}</p>
            <p>{{ date }}</p>
          </div>
          <div class="description" v-html="desc"></div>
<!--          <p class="description">-->
<!--            {{ desc }}-->
<!--          </p>-->
          <audio-player v-bind:key="id+'player'" :mp3-link="mp3Link" :id="id"/>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AudioPlayer from "../audioPlayer/audioPlayer";

export default {
  name: "homeEpisodeCard",
  components: { AudioPlayer},
  props: {
    image: String,
    title: String,
    desc: String,
    mp3Link: String,
    duration: String,
    date: String,
    id: Number,
    programName: String,
    CategoryName: String,
    ProgramId: Number,
    categoryId:Number,
  },
};
</script>

<style scoped lang="scss">
.episode-card {
  background-color: rgba(#000000, 0.025);
  border-radius: 7px;
  overflow: hidden;
  padding: 2em 2em;
  margin-bottom: 1.5em;

  img {
    width: 100%;
    object-fit: cover;
    object-position: center;
    border-radius: 7px;
  }

  .episode-remaining-info {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 1em 0 0.5em 0;

    .description{
      margin-bottom: 1em;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    p {
      margin-bottom: 0;
    }

    h4.episode-name {
      color: $myViolet;
      font-weight: 700;
      font-size: 2rem;
      margin-bottom: 0.5em;
    }

    .episode-info {
      margin-bottom: 1em;
      a, p {
        font-size: 0.85rem;
        color: $myBlue;
        font-weight: 700;
        margin-inline-end: 2em;
        display: inline-block;
      }

      p {
        display: inline-block;
      }
    }
  }
}

@media screen and (max-width: 992px) {
  .episode-card {
    padding: 2em 1em;
    .episode-remaining-info{
      h4{
        &.episode-name{
          font-size: 1.5rem;
        }
      }
    }
  }
}
</style>
