<template>
  <div class="tanwenServices">
    <div class="container">
      <div class="row px-lg-5">
        <div class="col-lg-3 mb-lg-0 mb-4">
          <div class="image-container" v-scroll-animation="'animate__fadeInDown'">
            <img
                :src="$store.state.generalStore.urlPrefixForImages+mainService.image"
                alt="Icon"
            />
          </div>
        </div>
        <div class="col-lg-9 d-flex align-items-center pe-lg-4">
          <div class="col-12">
            <div class="text-part">
              <h3 v-scroll-animation="'animate__fadeInDown'">{{ mainService.title }}</h3>
              <p v-scroll-animation="'animate__fadeInDown'">
                {{ mainService.text }}
              </p>
            </div>
            <div class="action-part">
              <hr v-scroll-animation="'animate__fadeInDown'"/>
              <router-link :to="{name:'Services'}" v-scroll-animation="'animate__zoomIn'"
                           class="more-detaiils-btn">
                تفاصيل أكثر
              </router-link>
            </div>
          </div>
        </div>
      </div>
      <div class="row mt-5 services-container pt-5 px-lg-5">
        <!--    Service Item    -->
        <div class="col-lg-3 col-md-6 col-12 mb-lg-0 mb-4" v-bind:key="item.form_id"
             v-scroll-animation="'animate__zoomIn'" v-for="item in services">
          <router-link :to="{name:'SingleService',params:{id:item.form_id}}" class="service-item ">
            <img
                class="img-fluid"
                :src="$store.state.generalStore.urlPrefixForImages+item.image"
                alt="icon"
            />
            <hr/>
            <p class="title">{{ item.title }}</p>
            <p class="description">
              {{ item.text }}
            </p>
          </router-link>
        </div>

      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "tanwenServices",
  props: {
    services: Array,
    mainService: Object,
  },
};
</script>

<style lang="scss" scoped>
.tanwenServices {
  padding-bottom: 3.5em;
  overflow-x: hidden;
  padding-top: 1em;

  .image-container {
    padding: 3.5em 0.5em;
    box-shadow: 0 0 15px rgba(#000000, 0.15);
    border-radius: 8px;

    img {
      width: 80%;
      display: block;
      margin: 0 auto;
    }
  }

  .text-part {
    h3 {
      color: $myViolet;
      font-weight: 700;
      font-size: 3.2rem;
    }

    p {
      font-size: 1.3rem;
    }
  }

  .action-part {
    display: flex;
    align-items: center;

    hr {
      flex-grow: 1;
      background-color: rgba(#000000, 0.35);
    }

    .more-detaiils-btn {
      margin-inline-start: 1em;
      padding: 0.5em 1.5em;
      border-radius: 1.5em;
      border: 2px solid rgba(#000000, 0.65);
      font-weight: 700;
      color: $myBlue;
      font-size: 1.05rem;
      transition: 0.3s ease-in-out;

      &:hover {
        color: #fff;
        background-color: $myBlue;
      }
    }
  }

  .services-container {
    //gap:2em 0em;
    .col-lg-3 {
      display: flex;
      justify-content: center;

      &:first-of-type {
        justify-content: flex-start;
      }

      &:last-of-type {
        justify-content: flex-end;

      }
    }

    .service-item {
      padding: 1.5em 1em;
      border-radius: 8px;
      box-shadow: 0 0 15px rgba(#000000, 0.1);
      transition: 0.5s cubic-bezier(0.47, 1.64, 0.41, 0.8);
      display: block;
      width: calc(100% - 1em);
      //margin-inline-end: 2em;
      //&:nth-of-type(1){
      //  margin-inline-end: 0;
      //}

      &:hover {
        transform: scale(1.1);
        box-shadow: 0 0 20px rgba(#000000, 0.2);
      }

      img {
        height: 7em;
        display: block;
        margin: 0 auto;
      }

      hr {
        width: 80%;
        display: block;
        margin: 1em auto;
        color: $myBlue;
      }

      .title {
        color: $myViolet;
        font-weight: 700;
        font-size: 1.4rem;
        text-align: center;
        margin-bottom: 0.25em;
      }

      .description {
        text-align: center;
        margin-bottom: 0.25em;
        color: $myBlue;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
  }
}

@media screen and (max-width: 992px) {
  .tanwenServices {
    .col-lg-3.mb-lg-0.mb-4 {
      display: none;
    }

    .services-container {
      .col-lg-3.mb-lg-0.mb-4 {
        display: block;
      }

      .col-lg-3 {
        justify-content: center !important;
      }

      .service-item {
        margin-inline-end: 0;
        width: 100%;
      }
    }
  }
}
</style>
