<template>
  <div class="tanwen-drawer" @click="closeDrawer(false,true)" v-bind:class="{notHomeDrawer: this.$route.path !== '/'}">
    <div class="drawer-container">
      <div class="watch-part">
        <p class="time-type">{{ this.$store.state.generalStore.drawerTime }}</p>
        <p class="time">{{ this.$store.state.generalStore.drawerTimeType }}</p>
      </div>
      <div class="links-part">
        <div class="signin just-mobile" @click="openSignIn" v-if="!$store.getters['user/getToken']">
          <p>تسجيل دخول</p>
        </div>
        <div class="signin" @click="signOut" v-else>
          <p>تسجيل خروج</p>
        </div>
        <router-link
            to="/"
            class="just-mobile"
            @click.stop="closeDrawer(true)"
        >الرئيسية
        </router-link
        >
        <router-link
            to="/podcast"
            class="just-mobile"
            @click.stop="closeDrawer(true)"
        >بودكاست
        </router-link
        >
        <router-link
            to="/services"
            class="just-mobile"
            @click.stop="closeDrawer(true)"
        >خدمات
        </router-link
        >
        <router-link
            to="/about"
            class="just-mobile"
            @click.stop="closeDrawer(true)"
        >من نحن
        </router-link
        >
        <router-link
            to="/contact"
            class="just-mobile"
            @click.stop="closeDrawer(true)"
        >اتصل بنا
        </router-link
        >
        <router-link to="/videos" @click.stop="closeDrawer(true)"
        >مرئيات
        </router-link
        >
        <router-link :to="{name:'Articles'}" @click.stop="closeDrawer(true)"
        >مقالات
        </router-link
        >
        <!--        <router-link :to="{name:'Courses'}" @click.stop="closeDrawer(true)"-->
        <!--          >دورات</router-link-->
        <!--        >-->
        <router-link :to="{name:'SingleService',params:{id:1}}" @click.stop="closeDrawer(true)"
        >أضف بودكاست
        </router-link
        >
        <router-link :to="{name:'SingleService',params:{id:3}}" @click.stop="closeDrawer(true)"
        >اكتب مع تنوين
        </router-link
        >

      </div>
      <div class="social-links">
        <a :href="social.url" target="_blank" title="Social Media" v-bind:key="social.id"
           v-for="social in this.$store.state.generalStore.socialMedia">
          <font-awesome-icon
              :icon="['fab', social.icon]"
          ></font-awesome-icon>
        </a>
      </div>
      <div class="close-btn" @click="closeDrawer(false,false,true)">
        <font-awesome-icon icon="times"/>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.tanwen-drawer {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 100001;
  width: 100vw;
  height: 100vh;
  background-color: rgba(#000000, 0.5);
  display: flex;
  justify-content: flex-end;
  pointer-events: none;
  opacity: 0;

  &.show {
    pointer-events: auto;
    opacity: 1;

    .drawer-container {
      transform: translateX(0%);
    }
  }

  &.notHomeDrawer {
    .drawer-container {
      background-color: rgba(#ffffff, 0.15);
    }
  }

  .drawer-container {
    height: 100vh;
    width: 25em;
    max-width: 100vw;
    background-color: rgba($myBlue, 0.5);
    backdrop-filter: blur(10px);
    border-radius: 0 16px 16px 0;
    padding: 1em 2em;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    transform: translateX(-100%);
    transition: 0.3s ease-out;

    .close-btn {
      display: none;
    }
  }

  .watch-part {
    display: flex;
    justify-content: center;
    align-items: flex-end;
    position: relative;

    p {
      margin-bottom: 0;

      &.time {
        color: #ffffff;
        font-size: 5rem;
        margin-inline-start: 0.5em;
        font-weight: 700;
      }

      &.time-type {
        color: $myYellow;
        position: absolute;
        bottom: 22%;
        right: 2%;
        font-weight: 700;
        font-size: 1.2rem;
      }
    }
  }

  .links-part {
    width: 100%;
    margin-top: 1em;
    position: relative;

    a ,.signin{
      cursor: pointer;
      display: block;
      width: 100%;
      //border-bottom: 1.5px solid #ffffff;
      color: #ffffff;
      //margin-bottom: 1.25em;
      padding-bottom: 0.5em;
      padding-top: 1em;
      padding-inline: 0.5em;
      position: relative;
      z-index: 1;
      transition: 0.3s ease-in-out;

      &.just-mobile {
        display: none;
      }

      &::before {
        content: "";
        position: absolute;
        bottom: 0;
        right: 0;
        width: 100%;
        height: 1px;
        opacity: 1;
        background-color: #ffffff;
        z-index: -1;
        transition: 0.3s ease-in-out;
      }

      &:hover {
        color: $myBlue;
        font-weight: 700;

        &::before {
          height: 100%;
          opacity: 1;
        }
      }
      p{
        margin-bottom: 0;
      }
    }
  }

  .social-links {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    margin-top: 2em;

    a {
      display: inline-block;
      margin-inline-end: 0.25em;

      &:hover {
        svg {
          color: $myYellow;
        }
      }

      svg {
        color: #ffffff;
        font-size: 1.5rem;
        transition: 0.3s ease-in-out;
      }
    }
  }
}

@media screen and (max-width: 991px) {
  .tanwen-drawer {
    .links-part {
      overflow-y: scroll;

      a,.signin {
        &.just-mobile {
          display: block;
        }
      }
    }
  }
}

@media screen and (max-width: 577px) {
  .tanwen-drawer {
    .drawer-container {
      border-radius: 0;
      width: 100vw;

      .close-btn {
        display: block;
        position: absolute;
        top: 5vh;
        right: 7vw;

        svg {
          font-size: 1.5rem;
          color: #ffffff;
          cursor: pointer;
        }
      }
    }
  }
}
</style>

<script>
import router from "@/router";

export default {
  name: "tanwenDrawer",
  data() {
    return {
      currentTime: "",
      timeType: "",
      lastTimeCalled: '',
    };
  },
  created() {
    this.lastTimeCalled = new Date().getTime();
  },
  methods: {
    closeDrawer(isLink = false, isFullBodyClick = false, isCloseBtn = false) {
      // debugger;
      if ((new Date().getTime() - this.lastTimeCalled) / 1000 > 1) {
        this.lastTimeCalled = new Date().getTime();
        if (isCloseBtn) {
          this.$store.commit("generalStore/changeDrawerState");
        } else {
          if (window.matchMedia("(max-width: 576px)").matches) {
            this.$store.commit("generalStore/changeDrawerState");
          }else{
            if(isFullBodyClick){
              this.$store.commit("generalStore/changeDrawerState");
            }
          }
        }
      }

      // this.$store.commit("generalStore/changeDrawerState");
      // if (isCloseBtn) {
      //   this.$store.commit("generalStore/changeDrawerState");
      // } else {
      //   if (isFullBodyClick) {
      //     if (window.matchMedia("(max-width: 576px)").matches) {
      //       this.$store.commit("generalStore/changeDrawerState");
      //     }
      //   }
      // }
      // if (isBtn) {
      //   this.$store.commit("generalStore/changeDrawerState");
      // } else {
      //   if (window.matchMedia("(max-width: 576px)").matches) {
      //     this.$store.commit("generalStore/changeDrawerState");
      //   }
      // }
      //Close drawer always
      // debugger;
      // this.$store.commit("generalStore/changeDrawerState");
    },
    openSignIn(){
      this.$store.commit("generalStore/changeSignInPopup");
    },
    signOut(){
      localStorage.removeItem('accessToken');
      this.$router.go(0);
    },
    // getTime() {
    //   let realCurrentTime = new Date().toLocaleTimeString([], {
    //     hour: "2-digit",
    //     minute: "2-digit",
    //   });
    //   this.currentTime = realCurrentTime.substring(
    //     0,
    //     realCurrentTime.length - 2
    //   );
    //   this.timeType = new Date()
    //     .toLocaleTimeString([], {
    //       hour: "2-digit",
    //       minute: "2-digit",
    //     })
    //     .slice(-2);
    // },
  },
  // created() {
  //   this.getTime();
  // },
  // mounted() {
  //   this.getTime();
  // },
};
</script>
