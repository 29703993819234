const animatedScrollObserver = new IntersectionObserver(
  (entries, animatedScrollObserver) => {
    entries.forEach((entry) => {
      if (entry.isIntersecting) {
        entry.target.classList.remove("beforeEnter");
        entry.target.classList.add("animate__animated");
        entry.target.classList.add(entry.target.getAttribute("tempClass"));
        entry.target.removeAttribute("tempClass");
        animatedScrollObserver.unobserve(entry.target);
      }
    });
  }
);

export default {
  bind: function (el, binding) {
    el.classList.add("beforeEnter");
    //console.log("animate__" + binding.expression);
    let animationClass = binding.expression.replace(/'/g, "");
    el.setAttribute("tempClass", animationClass);
    animatedScrollObserver.observe(el);
  },
};
