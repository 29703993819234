<template>
  <div class="container english-version">
    <div class="section-title">
      <h2 v-scroll-animation="'animate__fadeInDown'">استمتع بتجربة بودكاست</h2>
      <h1 v-scroll-animation="'animate__fadeInDown'">باللغة الانكليزية</h1>
    </div>
    <div class="content">
      <img v-scroll-animation="'animate__fadeInDown'"
           class="img-fluid"
        src="https://via.placeholder.com/1920x600"
        alt="English version image"
      />
    </div>
  </div>
</template>

<script>
export default {
  name: "enjoyEnglishVersion",
};
</script>

<style lang="scss" scoped>
.english-version {
  padding-top: 3em;
  padding-bottom: 4em;
}
.section-title {
  margin-bottom: 3em;
  h1 {
    text-align: center;
    color: $myViolet;
    font-weight: 700;
    font-size: 4.5rem;
  }
  h2 {
    text-align: center;
    font-weight: 400;
    font-size: 2.5rem;
  }
}
.content {
  padding-top: 2em;
  img {
    width: 100%;
    object-fit: cover;
    border-radius: 8px;
    overflow: hidden;
  }
}
@media screen and (max-width: 992px){
  .english-version{
    .section-title{
      h1{
        font-size: 3.5rem;
      }
      h2{
        font-size: 2rem;
      }
    }
  }
}
</style>
