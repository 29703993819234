<template>
  <div class="platform-item">
    <a :href="link" target="_blank">
<!--      <p class="alternative-text">{{ getText() }}</p>-->
      <p>{{ getText() }}</p>
      <img
          v-bind:src="getImage()" :alt="getAlternative()"
      />
<!--      <img-->
<!--          class="alternative-image"-->
<!--          v-bind:src="getImage()" :alt="getAlternative()"-->
<!--      />-->

    </a>
  </div>
</template>

<script>
export default {
  name: "podcastPlatformsItem",
  props: {
    slug: String,
    link: String,
  },
  data() {
    return {
      listenPlatforms: [
        {
          slug: "sound_cloud",
          image: require("../../assets/images/soundCloud-icon.png"),
          title: "Soundcloud",
          alt: "Sound cloud icon",
        },
        {
          slug: "spotify",
          image: require("../../assets/images/spotify-icon.png"),
          title: "Spotify",
          alt: "Spotify icon",
        },
        {
          slug: "google_podcast",
          image: require("../../assets/images/googlePodcast-icon.png"),
          title: "Google Podcast",
          alt: "Google podcast icon",
        },
        {
          slug: "apple_podcast",
          image: require("../../assets/images/applePodcast-icon.png"),
          title: "Apple Podcast",
          alt: "Apple podcast icon",
        },
        {
          slug: "anghami",
          image: require("../../assets/images/anghami-icon.png"),
          title: "Anghami",
          alt: "Anghami icon",
        },
      ],
    };
  },
  methods: {
    getImage() {
      let platform = this.listenPlatforms.find(
          (item) => item.slug === this.slug
      );
      return platform.image;
    },
    getText() {
      let platform = this.listenPlatforms.find(
          (item) => item.slug === this.slug
      );
      return platform.title;
    },
    getAlternative() {
      let platform = this.listenPlatforms.find(
          (item) => item.slug === this.slug
      );
      return platform.alt;
    },
  },
}
</script>

<style scoped>

</style>