<template>
  <div class="home-slider container">
    <div class="section-title">
      <h3 v-scroll-animation="'animate__fadeInDown'">{{ title }}</h3>
      <div class="col-lg-4 offset-lg-4">
        <p v-scroll-animation="'animate__fadeInDown'">
          {{ subTitle }}
        </p>
      </div>
      <div class="d-flex justify-content-center">
        <router-link :to="{name:'Podcast'}" class="mainYellowBtn" v-scroll-animation="'animate__fadeInDown'">بودكاست
          تنوين
        </router-link>
      </div>
    </div>
    <swiper
        dir="rtl"
        ref="mySwiper"
        class="programs-slider"
        :options="swiperOptions"
        @slideChange="sliderChange"
    >
      <swiper-slide v-scroll-animation="'animate__zoomIn'" v-bind:key="item.id" v-for="item in programs">
        <router-link :to="{name:'Program',params:{id:item.id}}" class="program-card">
          <img :src="item.image_full_path" alt="program Image"/>
          <h5>{{ item.title }}</h5>
          <p>
            {{ item.short_description }}
          </p>
        </router-link>
      </swiper-slide>
      <div class="swiper-pagination" slot="pagination"></div>
    </swiper>
    <!--    <div class="wave-container">-->
    <!--      <img id="wave-img" :src="wave" alt="Wave" rel:auto_play="0">-->
    <!--    </div>-->
  </div>
</template>

<script>
import SuperGif from "libgif/libgif";

export default {
  props: {
    programs: Array,
    title: String,
    subTitle: String,
  },
  components: {},
  data() {
    return {
      swiperOptions: {
        slidesPerView: 3,
        spaceBetween: 0,
        loop: false,
        centeredSlides: true,
        centerInsufficientSlides: true,
        speed: 500,
        initialSlide: 1,
        pagination: {
          el: ".swiper-pagination",
          clickable: true,
        },
        autoplay: {
          delay: 4000,
        },
        breakpoints: {
          220: {
            slidesPerView: 1,
          },
          767: {
            slidesPerView: 2,
            centeredSlides: false,
            centerInsufficientSlides: false,
          },
          1024: {
            slidesPerView: 3,
          },
        },
      },
      wave: require("../../assets/gifs/wave-1.gif"),
      lastSlideIndex: 0,
      waveLibGif: '',
    };
  },
  methods: {
    sliderChange(mySlider) {
      if (mySlider.activeIndex > this.lastSlideIndex) {
        this.wave = require("../../assets/gifs/wave-2.gif");
        // setTimeout(() => {
        //   this.waveLibGif.play();
        //   setTimeout(() => {
        //     this.waveLibGif.pause();
        //   }, 1200);
        // }, 200);
      } else {
        this.wave = require("../../assets/gifs/wave-1.gif");
        // setTimeout(() => {
        //   this.waveLibGif.play();
        //   setTimeout(() => {
        //     this.waveLibGif.pause();
        //   }, 1200);
        // }, 200);
      }
      this.lastSlideIndex = mySlider.activeIndex;
    },
  },
  // created() {
  //
  // },
  // mounted() {
  //   let imageTag = document.getElementById("wave-img");
  //   this.waveLibGif = new SuperGif({gif: imageTag, loop_mode: false,});
  // },
};
</script>

<style lang="scss" scoped>
.home-slider {
  position: relative;
  z-index: 4;
  overflow-x: hidden;

  .wave-container {
    display: flex;
    justify-content: center;
    height: calc(9em);
    overflow: hidden;

    .vue-freezeframe {
      width: 25em;
      max-width: 80vw;
    }
  }
}

.section-title {
  margin-bottom: 1.5em;

  h3 {
    color: $myViolet;
    text-align: center;
    font-weight: 700;
    font-size: 4rem;
    margin-bottom: 0.35em;
  }

  p {
    text-align: center;
    font-size: 1rem;
    color: #323232;
    margin-bottom: 2em;
  }
}

.programs-slider {
  overflow: hidden;

  .swiper-pagination{
    display: none;
  }

  .swiper-slide {
    a {
      transform: scale(0.7);
      opacity: 0.4;
      transition: 0.5s ease-in-out;
    }


    &.swiper-slide-active {
      a {
        transform: scale(1);
        opacity: 1;
      }

    }
  }

  .program-card {
    padding-top: 1em;
    padding-left: 2em;
    padding-right: 2em;
    display: block;

    img {
      width: 100%;
      margin-bottom: 1em;
      border-radius: 10px;
      box-shadow: 0 0 12px rgba(#000000, 0.3);
    }

    h5 {
      color: $myBlue;
    }

    p {
      color: #000;
      display: -webkit-box;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
}

@media screen and (max-width: 992px) {
  .home-slider {
    margin-top: 3em;

    h3 {
      font-size: 3rem;
    }
  }
  .programs-slider {
    padding-bottom: 2.5em;

    .swiper-pagination{
      display: block;
    }
    .swiper-slide {
      transform: scale(1);
      opacity: 1;

      &.swiper-slide-active {
        transform: scale(1);
        opacity: 1;
      }
    }

    .program-card {

    }
  }
}
</style>
