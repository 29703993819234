<template>
  <div
      class="searchPopup"
      v-bind:class="{ show: this.$store.state.generalStore.searchPopupState }"
  >
    <div class="search-container">
      <font-awesome-icon class="close-icon" icon="times" @click="closeSearch(false)"/>
      <form @submit="searchFunction">
        <input
            v-model="searchKeywords"
            type="text"
            placeholder="أدخل نص البحث"
            required
        />
        <font-awesome-icon icon="search" @click="searchFunction(true)"/>
      </form>
    </div>
  </div>
</template>

<script>
export default {
  name: "tanwenSearchPopup",
  data() {
    return {
      searchKeywords: "",
    };
  },
  methods: {
    searchFunction(isBtn) {
      if (!isBtn) {
        event.preventDefault();
      }
      // console.log(this.searchKeywords);
      if(this.searchKeywords!==""){
        this.$router.push({name: "Search", params: {keyword: this.searchKeywords}});
        this.$store.dispatch("generalStore/changeSearchPopupState");
      }

    },
    closeSearch() {
      this.$store.dispatch("generalStore/changeSearchPopupState");
    },
  },
  // created() {
  //   let searchContainer = document.getElementsByClassName("searchPopup")[0];
  //   searchContainer.addEventListener('click',function (e){
  //     console.log(e.target);
  //   });
  // },
  mounted() {
    let searchContainer = document.getElementsByClassName("searchPopup")[0];
    searchContainer.addEventListener('click', (e)=>{
      if (e.target === searchContainer) {
        // console.log(this);
        this.$store.dispatch("generalStore/changeSearchPopupState");
      }
    });
  },
  destroyed() {
    // let searchContainer = document.getElementsByClassName("searchPopup")[0];
    // searchContainer.removeEventListeners();
  },
};
</script>

<style lang="scss" scoped>
.searchPopup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(#000000, 0.7);
  z-index: 100001;
  display: none;
  justify-content: center;
  align-items: center;

  &.show {
    display: flex;
  }

  .search-container {
    padding: 1.5em 2em;
    //background-color: #fff;
    border-radius: 4px;
    position: relative;

    form {
      position: relative;
      border-radius: 4px;
      overflow: hidden;

      input {
        width: 25em;
        max-width: 80vw;
        padding: 0.5em 0.75em 0.5em 2em;
        border: none;
        //border-bottom: 2px solid rgba(#000000, 0.5);
        box-shadow: 0 0 12px rgba(#000000, 0.15);
        outline: none !important;
      }

      svg {
        cursor: pointer;
        position: absolute;
        top: 50%;
        left: 0.5rem;
        transform: translateY(-50%);
        color: rgba(#000000, 0.7);
      }
    }

    .close-icon {
      position: fixed;
      top: 0.8em;
      right: 7.3vw;
      color: #ffffff;
      font-size: 2rem;
      cursor: pointer;
    }
  }
}
</style>
